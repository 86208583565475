<template>
    <div id="page-header" class="tyc-header">
        <div class="tyc-header-container">
            <a href="/" class="tyc-header-logo">
                <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" height="35" alt="">
                <span>经济运行平台</span>
            </a>
            <div class="tyc-header-middle">
                <div class="tyc-header-suggest">
                    <ul class="tyc-header-suggest-tab">
                        <li v-for="item in qClassList" :key="item.id" class="tyc-header-suggest-tab-item" :class="{'tyc-header-suggest-tab-active':item.isCheck}" @click='changeQueryClass(item)'>{{item.name}} </li>
                    </ul>
                    <div class="tyc-header-suggest-content" @click.stop>
                        <div class="tyc-header-suggest-input tyc-header-suggest-input-reset _e3f88 _44c65">
                           <el-input v-model="tycSearch" :placeholder="qClassList.find(t=>t.isCheck).desc"  @input="inputFun"  @focus="inputFun"  />
                        </div>
                        <el-tooltip :visible="tooltipInfo"  effect="light"  placement="bottom-start" trigger='click'  :auto-close='2000'>
                            <template #content>
                                <span>{{tooltipInfo}}</span>
                            </template>
                            <button class="_50ab4 tyc-header-suggest-button _52bf6" @click="btnFun">
                                <span>查一下</span>
                            </button>
                        </el-tooltip>
                        <div class="tyc-header-suggest_popover_box" v-if="isShowQueryRes" >
                            <ul>
                                <li v-for="item in queryResList" :key="item.code">
                                    <a v-if='qClassList.find(t=>t.isCheck).id==1'  @click.prevent="pushSearch(item)"  >
                                        <span class="firstLine">{{item.parent?item.parent+'-':''}}{{item.name}}</span>
                                    </a>
                                    <a v-else @click.prevent="pushSearch(item)" class="popover_box_item">
                                         <span >{{item.name}}</span>
                                        <span class="popover_box_item_primary">{{item.parent}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tyc-header-right">
                <div class="tyc-header-nav">     
                    <div class="tyc-header-nav-item tyc-nav-user">
                        <!-- <el-button v-if=" !currentUser" type="primary"  @click='$store.commit("setIsLoginVisable", true);'>登录/注册</el-button> -->
                        <el-link v-if=" !currentUser || currentUser?.expires<Math.round(new Date().getTime()/1000)" :underline="false" type="primary" @click.prevent='$store.commit("setIsLoginVisable", true);'>登录/注册</el-link>
                        <el-popover v-if="currentUser && currentUser?.expires>=Math.round(new Date().getTime()/1000)" placement="bottom" :width="200" trigger="hover" >
                        <template #reference>
                            <el-avatar   :size="30" :src="currentUser?.picUrl" style="cursor: pointer;" ></el-avatar>
                        </template>
                        <div class="user-float" >
                            <div class="user-name">
                                <span style="">{{currentUser?.userName}}</span>
                            </div>
                            <ul>
                                <li class="css-1e76yen" @click="push(`/people`,'people')">
                                    <i class="iconfont ec-icon-s-user"></i>
                                    <span>我的主页</span>
                                </li>
                                <li class="css-1e76yen" @click="push('/user-center','usercenter')">
                                    <i class="iconfont ec-icon-setting-fill"></i>
                                    <span>个人中心</span>
                                </li>
                                <li class="css-1e76yen" @click="loginOut">
                                    <i class="iconfont ec-icon-exit"></i>
                                    <span>退出</span>
                                </li>
                            </ul>
                        </div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="$store.state.loginVisable"
            title=""
            :width='420'
            custom-class="login-dialog"
            destroy-on-close
            :close-on-click-modal='false'
            append-to-body
            @close='loginCloseFun'>
            <login></login>
        </el-dialog>
    </div>
</template>
<script>
import {qcList} from '@/utils/index.js'
import { ref } from '@vue/reactivity'
import { computed, inject } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import {getItemList} from '@/http/homeApi'
import {getIsLogin,getUserRightFun} from '@/http/basicsApi'
import Login from '@/components/Login.vue'
export default {
    components:{
        Login
    },
    setup() {
        let route=useRoute();
        let router=useRouter();
        let reload=inject('reload');
        let currentUser=computed(()=>JSON.parse( localStorage.getItem('currentUser')));//当前用户信息
        console.log(currentUser.value);
        let qClassList=ref(JSON.parse(JSON.stringify(qcList)));//深度拷贝
        let tycSearch=ref('')//搜索
        let tooltipInfo=ref('')//提示消息
        let queryResList=ref([])//搜索的结果
        let isShowQueryRes=ref(false)//是否显示搜索结果
        let regionList=inject('regionList')
       
        //改变查询的分类
        const changeQueryClass=(item)=>{
            qClassList.value.forEach(t=>{
                if(t.id==item.id){
                    t.isCheck=true
                }
                else if(t.isCheck){
                    t.isCheck=false
                }
                tycSearch.value='';
            });
        }
        //单击【查一下】
        const btnFun=()=>{
            tooltipInfo.value='';
            if(!tycSearch.value){
              
                tooltipInfo.value="请输入搜索的关键词";
                
                return;
            }
            //判断是地区查、指标查
            let selType=qClassList.value.find(t=>t.isCheck);
            if(selType.id==1){//地区
                let filterAry=queryResList.value.filter(t=>t.name.indexOf(tycSearch.value)>-1);
                console.log
                if(filterAry.length==1){//能够唯一确定地区就直接跳转到地区经济的首页
                    pushSearch(filterAry[0]);
                }
                else{//如果不能确定就提示
                    tooltipInfo.value="请确定要搜索的地区名称";
                } 
            }
            else if(selType.id==2){
                pushSearch({name:tycSearch.value});
            }
        }
        const pushSearch=(item)=>{
            getIsLogin().then((res)=>{
                //console.log(res);
                if(res && res.code==20000){//登录成功
                    let selType=qClassList.value.find(t=>t.isCheck);
                    //console.log(selType);
                    if(selType.id==1){
                        localStorage.setItem('curRegionCode',item.code)
                        const { href } = router.resolve({
                            path: '/home',
                        });
                        //console.log(href)
                        window.open(href, "_blank");
                    }
                    else if(selType.id==2){
                        const { href } = router.resolve({
                            path: '/search',
                            query: {
                                key: item.name
                            }
                        });
                        window.open(href, "_blank");
                    }
                }
            })
            
        }
        const inputFun=async()=>{
            queryResList.value=[];
            isShowQueryRes.value=true;
            if(tycSearch.value){
                //判断是地区查、指标查
                let selType=qClassList.value.find(t=>t.isCheck);
                if(selType.id==1){//地区查
                    let subRegion=[];
                    regionList.value.forEach(t=>{
                        if(t.name.indexOf(tycSearch.value)>-1){
                            queryResList.value.push({code:t.code,name:t.name,parent:''});
                            subRegion=[...subRegion,...t.children.map(m=>{return { ...m,parent:t.name}})];
                        }
                        let filterAry= t.children.filter(m=>m.name.indexOf(tycSearch.value)>-1 );
                        if(filterAry.length>0){
                            let pushAry=filterAry.map(m=>{return { ...m,parent:t.name}});
                            queryResList.value=[...queryResList.value,...pushAry];
                        }
                    })
                    let aryLength=queryResList.value.length;
                    if(aryLength<8 && subRegion.length>0){

                        let pushAry=subRegion.filter((t,index)=>index<8-aryLength-1);
                        console.log(pushAry);
                        queryResList.value=[...queryResList.value,...pushAry];
                    }
                    else if(aryLength>8){
                        queryResList.value=queryResList.value.filter((t,index)=>index>8-1);
                    }
                }
                else if(selType.id==2){//指标查
                    getItemListFun();
                }
                
            }
        }
        //搜索查询指标
        const getItemListFun= async()=>{
            let params={
                search:tycSearch.value,
                pageIndex:1,
                pageSize:10
            }
            let res=await getItemList(params);
            if(res.code==20000){
                res.data.forEach(t=>{
                    if(t.parentId==20){
                        t.path='search'
                    }
                    else if(t.parentId==30){
                        t.path='search/ecoIndustrySearch';
                    }
                    if(t.parentId==50){
                        t.path='search/microEcoSearch'
                    }
                    else if(t.parentId==70){
                        t.path='search/ecoArticleSearch';
                    }
                });
                queryResList.value=res.data;
            }
        }
        const hideQueryResFun=()=>{
            isShowQueryRes.value=false
        }
        //跳转
        const push=async (path,name)=>{
            console.log(path);
            if(await getUserRightFun(name,0)){
                router.push(path)
            }
            
        }
        //退出
        const loginOut=()=>{
            localStorage.removeItem("accesstoken");
            localStorage.removeItem("currentUser");
            localStorage.removeItem("refreshToken"); 
            if(route.path=='/' || route.path=='/index'){
                reload();
            }
            else{
                router.push('/')
            }
        }
        //关闭登录框
        const loginCloseFun=()=>{
            router.push('/')
        }
        return{
            currentUser,
            qClassList,
            tycSearch,
            changeQueryClass,
            inputFun,
            btnFun,
            tooltipInfo,
            queryResList,
            isShowQueryRes,
            hideQueryResFun,
            push,
            loginOut,
            loginCloseFun,
            pushSearch
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.tyc-header .tyc-header-middle {
    left: 335px;
    position: absolute;
    top: 13px;
    top: 50%;
    transform: translateY(-50%);
}
.tyc-header-suggest-tab {
    color: #666;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}
.tyc-header-suggest-tab .tyc-header-suggest-tab-item.tyc-header-suggest-tab-active {
    background: $themeColor;
    border-radius: 2px 2px 0 0;
    color: #fff;
}
.tyc-header-suggest-tab .tyc-header-suggest-tab-item {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 55px;
}
.tyc-header-suggest-content {
    display: flex;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    overflow: visible;
    position: relative;
    width: 440px;
}
.tyc-header-suggest-content .tyc-header-suggest-input, .tyc-header-suggest-content .tyc-header-suggest-input.tyc-header-suggest-input-reset {
    font-size: inherit;
    height: 100%;
    line-height: inherit;
    width: 100%;
    ::v-deep .el-input__inner{
        border: none;
        height: 28px;
    }
}
.tyc-header-suggest-content .tyc-header-suggest-input {
    border: 1px solid $themeColor;
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;
    flex-grow: 1;
    padding-left: 8px;
    position: relative;
}
.tyc-header-suggest_popover_box{
    overflow: visible;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
    overflow: hidden;
    top: 30px;
    width: 368px;
    position: absolute;
}
.tyc-header-suggest_popover_box li {
    align-items: center;
    border-top: 1px solid #f3f3f3;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 3px 16px;
    &:hover {
        background-color: rgba(0,132,255,.08);
    }
    a{
        width:100%;
    }
    a.popover_box_item{
        .popover_box_item_primary {
            background-color: rgba(68,157,230,.1);
            color: #449de6;
            line-height: 1.5;
            float: right;
            margin-top: 8px;
            padding:0 5px;
            border-radius: 4px;
        }
    }
}
.tyc-header-suggest_popover_box .firstLine {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    display: block;
}
._e3f88._44c65 {
    font-size: 14px;
    height: 36px;
    line-height: 22px;
}
._e3f88 {
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    padding: 0 12px;
    position: relative;
    transition: all .3s;
    width: 100%;
}
._e3f88 ._cc76e {
    border: none;
    display: inline-block;
    font-size: inherit;
    height: 100%;
    line-height: inherit;
    outline: none;
    padding: 0;
    width: 100%;
}

._e3f88 ._03321 {
    text-align: left;
}
._cc76e:placeholder-shown {
    text-overflow: ellipsis;
}
._cc76e {
    border-radius: 2px;
    padding: 0 12px;
    transition: all .3s;
    width: 100%;
}
._50ab4._6c649:hover, ._cc76e {
    border: 1px solid #e0e0e0;
    color: #333;
}
.tyc-header-suggest-content .tyc-header-suggest-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: none;
    flex-shrink: 0;
    font-size: inherit;
    height: 100%;
    line-height: 30px;
    white-space: nowrap;
    width: 72px;
}

._50ab4._52bf6 {
    background: $themeColor;
    border: none;
    color: #fff;
}
._50ab4 {
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    line-height: 36px;
    padding: 0 16px;
    transition: all .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

</style>