//import "babel-polyfill"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'

import hasPermission from "./utils/btnPernission";
//import './styles/index.scss'
import './styles/base.scss'
import './icons/iconfont.css'
//import './icons/iconfont.js'
import '@/conmon/fonts/font.css'
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// markDown编辑器组件 v-md-editor
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
const app = createApp(App)
installElementPlus(app)

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
//library.add(fas)
//app.use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
app.use(store).use(router).use(hasPermission).use(VMdPreview).mount('#app')
//清除浏览器 localStorage 缓存
//1、更新package.json中的 version 值，每次打包往上递增
//2、main.js中，根据 version 判断是否进行缓存清理
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem('appVersion')
if(VUE_APP_VERSION != vers){
  localStorage.clear()
  window.localStorage.setItem('appVersion', VUE_APP_VERSION)
  location.reload()
}