<template>
    <div>
        <div class="login-panel-head clearfix">
            <img src="https://data.chinaecono.com/images/logo.png?t=1683356233"  > 
        
            <div class="login-tab"><a :class="{'active':loginType==1}" @click="loginType=1;">快捷登录</a></div>
            <div class="login-tab"><a :class="{'active':loginType==0}" @click="loginType=0;">密码登录</a></div>
        </div>
        <el-form :model="loginForm"  status-icon  :rules="loginFormRules" ref="loginFormRef" label-width="100px">
            <!-- 密码登录 -->
            <el-form-item v-if="loginType==0" label-width='0' prop="tel">
                <el-input v-model="loginForm.tel" placeholder="请输入手机号" autocomplete="off"  >
                    <template #prepend>
                        <span >中国 +86</span>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item v-if="loginType==0" label-width='0' prop="password">
                <el-input type="password"  placeholder="密码" v-model="loginForm.password"  autocomplete="off" >
               
                </el-input>
            </el-form-item>
           
            <!-- 验证码登录 -->
            <el-form-item v-if="loginType==1" label-width='0' prop="tel">
                <el-input v-model="loginForm.tel" placeholder="请输入手机号" autocomplete="off"  >
                    <template #prepend>
                        <span>中国 +86</span>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item v-if="loginType==1" label-width='0' prop="verifyCode" style="border: 1px solid #DCDFE6;">
                <el-input v-model="loginForm.verifyCode" placeholder="输入6位验证码" oninput="value=value.replace(/[^0-9.]/g,'')" class="input-verifycode"/>
                <div class="vcode-area fr">
                    <el-link :underline="false" href="#" @click.prevent='sendVerifyCodeFun' v-if='isSend' class="vcode-btn">获取验证码</el-link>
                    <el-link :underline="false" disabled v-if='!isSend' class="vcode-btn">{{sendMsg}}</el-link>
                </div>
            </el-form-item>
            <el-form-item label-width='0'>
                <!-- <el-button  class="subBtn" type="primary" @click="submitForm()">{{loginType==1?'登录/注册':'立即登录'}}</el-button> -->
                <el-button  class="subBtn" type="primary" @click="submitForm()">立即登录</el-button>
            </el-form-item>
             <!-- 忘记密码 -->
            <el-form-item  label-width='0' style="margin-top:-20px;">
                <el-link :underline="false" href="/#/register">免费注册</el-link>
                <el-link :underline="false" href="/#/forgetPassword" style="padding-left:20px;">忘记密码</el-link>
            </el-form-item>
        </el-form>
        <!-- 滑块验证 -->
        <el-dialog v-model="erifyVisible" title="请完成安全验证" destroy-on-close :close-on-click-modal='false' :show-close="false" :width='360' custom-class="verify-dialog"  >
          <SlideVerify @loginfun="loginfun" accuracy='2'></SlideVerify>
        </el-dialog>
        <div  class="text-gray text-center font-12  login-tip" >登录即表示已阅读并同意
            <a target="_blank" href="/#/help/userContent" class="text-gray">《用户协议》</a>
            和
            <a target="_blank" href="/#/help/privacyPolicy" class="text-gray">《隐私权政策》</a>
        </div>
        <!-- <div v-if="loginType==1" class="font-12 text-center text-gray ">未注册的手机号验证后将自动创建新账号</div> -->
    </div>
</template>
<script>
import { inject, ref } from "vue"; 
import SlideVerify from '@/components/SlideVerify'
import { ElMessage } from 'element-plus'
import {login, sendVerifyCode} from "../http/loginApi";  
//import{registerUser} from '../http/homeApi'
//import {setUserPicUrl} from "../http/basicsApi";  
import { useRoute,useRouter } from 'vue-router';
import store from '../store'
//import{setCookie} from '@/utils/index.js'
export default {
  name:'login',
  components:{
      SlideVerify
  },
  setup() {
    const reload =inject("reload");//provide, inject配合router来刷新页面
    let erifyVisible=ref(false);//滑块验证是否显示
    let loginType=ref(1);//0：密码登录,1:验证码登录
    const route=useRoute();//当前路由
    const router=useRouter();
    let loginForm=ref({
        userName:'',
        password:'',
        tel:'',
        verifyCode:'',
    })
    let loginFormRef = ref(null);
    
    // 引入
    // const Base64 = require('js-base64').Base64
    // // 在页面加载时从cookie获取登录信息
    // let account = getCookie("account")
    // let password =Base64.decode(getCookie("password"))
    // if(account)
    // {
    //   loginForm.value.userName = account
    //   loginForm.value.password = password
    //   //loginForm.value.verifyCode = ''
    // }

    //登录提交验证
    let loginFormRules = {
      userName: [
        { required: true, message: "请输入用户名", trigger: "blur" },
        { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 1, max: 32, message: "长度在1-32个字符", trigger: "blur" },
      ],
      tel: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
      ],
       verifyCode: [
        { required: true, message: "请输入验证码", trigger: "blur" },
        { min: 6, max: 6, message: "长度为6字符", trigger: "blur" },
      ]
    };


    let sendMsg=ref('发送验证码')
    let isSend=ref(true)
    //获取验证码
    const sendVerifyCodeFun=()=>{
        let obj = loginFormRef.value;  
        obj.validateField(['tel'],(isValid) => {
            if(!isValid){
                sendVerifyCode(loginForm.value.tel,0)
                .then((res) => {
                    if ( res.code == 20000) {
                        //Message.success(res.data.msg)
                        isSend.value = false;
                        let timer = 60;
                        sendMsg.value = timer + "s后可重发";
                        const timeFun = setInterval(() => {
                            --timer;
                            sendMsg.value = timer + "s后可重发";
                            if (timer == 0) {
                                isSend.value = true;
                                sendMsg.value = "获取验证码";
                                clearInterval(timeFun);
                            }
                        }, 1000);
                    }
                });
            }
        });
    }
    //登录
    let submitForm = () => {  
      //return false;
      let obj = loginFormRef.value;  
      obj.validate((valid) => {
          console.log(valid);
        if (valid) { 
            if(loginType.value==0){//密码登录
                erifyVisible.value=true;
            }
            else{//验证码登录
                loginfun();
            }
            
        } else {
          return false;
        }
      });
    };
    const  loginfun=()=>{
        let params={...loginForm.value,...{loginType:loginType.value},...{appId:20}};
        erifyVisible.value=false;
        login(params).then(function(response){   
            // if(response.code==30004){//未注册
            //     //进行注册
            //     let r_params={
            //         type:1,
            //         tel:params.tel,
            //         verifyCode:params.verifyCode,
            //     }
            //     registerUser(r_params).then((res)=>{
            //         if(res.code==20000){
            //             //注册成功后登录
            //             login(params).then(function(loginRes){
            //                 if(loginRes.code==20000)
            //                 {
            //                     loginInfoFun(loginRes.data,route);
            //                 }
            //             });
            //         }
            //         else{
            //             ElMessage.error({
            //                 message: res.message, 
            //             });  
            //         }
            //     });
            // }
            // else if(response.code==20000)
            if(response.code==20000)
            { 
                loginInfoFun(response.data);
            } 
            else
            {
                ElMessage.error(response.message);
            }
        });
    }
    const loginInfoFun=(data)=>{
        localStorage.setItem("accesstoken",data.accessToken); 
        localStorage.setItem("refreshToken",data.refreshToken);
        //localStorage.removeItem('navMenuData');//移除菜单列表
        localStorage.setItem("currentUser",JSON.stringify(data.currentUser)); 
        // console.log();
        // 设置菜单为未加载状态
        //store.commit("setMenuRouteLoadStatus", false);
        store.commit("setIsLoginVisable", false);
        ElMessage.success({
                message: '登录成功', 
        });   
        console.log(route);
        //登录成功后，看看是否是其他页面跳转过来登录的，如果是，还的跳转回去； 
        if(route.query.redirect)
        {
            router.push(route.query.redirect);  //跳转到之前的页面
        }
        else if(route.path=='/login'){
            router.push('/'); 
        }
        else
        {
            reload();
        } 
    }
    //存储用户+密码
    // const setUserInfo=()=>{
    //   // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
    //         // 如果没有勾选，储存的信息为空
    //         if(loginForm.value.remember){
    //             setCookie("account",loginForm.value.userName)
    //             // base64加密密码
    //             let passWord = Base64.encode(loginForm.value.password)
    //             setCookie("password",passWord)    
    //         }else{
    //             setCookie("account","")
    //             setCookie("password","") 
    //         } 
    // }
    return {
        loginType,
        loginFormRules,//登录校验登录
        loginForm, 
        submitForm,
        loginFormRef,
        erifyVisible,
        sendVerifyCodeFun,
        sendMsg,
        isSend,
        loginfun
    };
  },
};

</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.login-panel-head{
    text-align: center;
    margin-bottom: 40px;
    img{
        margin: 20px 0;
        //淄博市项目平台调整 2023-04-17
        //height: 90px;
    }
}
.login-tab {
    float: left;
    width: 50%;
    text-align: center;
    margin-top:10px;
}

.login-tab>a.active {
    color: $specialtext-color;
    border-bottom: solid 2px $specialtext-color;
}
.login-tab>a {
    padding: 12px 0;
    font-size: 16px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: solid 2px #128bed00;
}
.input-verifycode{
    width: 70%;
    ::v-deep .el-input__inner{
        border: none;
    }
}
.vcode-area {
    height: 40px;
    width: 100px;
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    top: 0px;
}
.vcode-btn {
    top: 50%;
    transform: translateY(-50%);
    width: 86px;
    text-align: center;
    font-size: 12px;
    position: absolute;
    right: 4px;
    cursor: pointer;
    min-width: 88px;
    display: inline-block;
}
.vcode-btn:after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 28px;
    background: #d8d8d8;
    right: 90px;
    top: 6px;
    height: 25px;
}
.f-title {

    text-align: center;
    margin: 52px auto;
}
.subBtn {
    width: 100%;
    font-size: 18px;
    background: $themeColor;
    border: 1px solid $themeColor;
    font-weight: 600;
    color: #fff;
}
.login-tip {
    margin-top: 76px;
    margin-bottom: 5px;
}
// ::v-deep .el-input{
//     border-bottom: 1px solid #DCDFE6;
// }
// ::v-deep .el-input__inner{
//     border: none;
//     color: #606266;
//     border-radius: 0px;
//     padding: 0;
// }
// ::v-deep .el-input-group__prepend {
//     background-color: transparent;
//     color: #909399;
//     vertical-align: middle;
//     display: table-cell;
//     position: relative;
//     border:none;
   
//     border-radius: 0px;
//     padding: 0px;
//     width: 1px;
//     white-space: nowrap;
// }
// ::v-deep .el-link.el-link--default{
//     color: #3F94FD !important;
//     &:hover{
//         color: #76839b !important;
//     }
//     &:focus{
//         color: #76839b !important;
//     }
// }
</style>
