<template>
    <div class="app-nfooter">
        <div class="footer-top-info">
            <div class="footer-top clearfix">
                <div class="about">
                    <h4>关于我们</h4>
                    <ul class="list-unstyled">
                        <li><a rel="nofollow" href="/#/help/aboutus">关于我们</a></li>
                        <li><a rel="nofollow" href="/#/help/contact">联系我们</a></li>
                        <li><a rel="nofollow" href="/#/help/disclaimer">免责声明</a></li>
                        <li><a rel="nofollow" href="/#/help/userHelp">用户帮助</a></li>
                        <li><a rel="nofollow" href="/#/help/userContent">用户协议</a></li>
                        <li><a rel="nofollow" href="/#/help/privacyPolicy">隐私权政策</a></li>
                        <li><a rel="nofollow" href="/#/help/feedback">意见反馈</a></li>
                    </ul>
                    <ul class="list-unstyled">
                       
                        <li><a rel="nofollow" href="https://data.chinaecono.com/pdf/易可诺企业宣传手册.pdf">宣传手册</a></li>
                        <li><a rel="nofollow" href="https://data.chinaecono.com/pdf/易可诺信息公司业务简介.pdf">业务简介</a></li>
                        <li><a rel="nofollow" href="https://data.chinaecono.com/pdf/易可诺产品白皮书.pdf">产品白皮书</a></li>
                        <li><a rel="nofollow" href="https://data.chinaecono.com/pdf/经济运行精准施策建议报告.pdf">建议报告</a></li>
                    </ul>
                </div> 
                <div class="contact">
                    <h4>联系方式</h4> 
                    <ul class="list-unstyled">
                        <li>工作时间: 周一至周五 9:00-18:00</li> 
                        <li>邮箱：<a href="mailto:market@chinaecono.com">market@chinaecono.com</a></li>
                        <li>微信公众号：chinaecono</li> 
                        <li>地址：北京市海淀区中关村大街1号17层M22号</li>
                    </ul>
                </div>
                <div class="qrcode">
                    <div class="qrcode-item">
                        <h4 class="ma_xcx">微信公众号</h4> 
                        <img src="https://data.chinaecono.com/images/qr_code.jpg" alt="经济运行微信公众号">
                    </div>
                    <div class="qrcode-item">
                        <h4 class="ma_xcx">企业微信</h4> 
                        <img src="https://data.chinaecono.com/images/wx_code.jpg" alt="企业微信">
                    </div>
                </div>
            </div>
            <div class="footer-link">
                <div class="footer-row clearfix">
                    <div class="footer-row-head">数据来源：</div>
                    <div class="footer-row-content clearfix">
                        <span class="item">国家统计局</span> 
                        <span class="item">经济年鉴</span> 
                        <span class="item">行业年鉴</span> 
                        <span class="item">人社部</span> 
                        <span class="item">教育部</span> 
                        <span class="item">住建部</span> 
                        <span class="item">交通部</span> 
                        <span class="item">民政部</span> 
                        <span class="item">水利部</span> 
                        <span class="item">财政部</span> 
                        <span class="item">土地市场网</span>
                        <span class="item">海关总署</span> 
                    </div>
                </div>
            </div>
        </div> 
        <div class="footer-copy-bg">
            <div class="font-13 footer-container">
                <img src="https://data.chinaecono.com/images/logo_w_1.png?t=1683356233" alt="" >
                <a href="javascript:void(0)" title="经济运行">©2018-2023</a> 
                北京易可诺信息科技有限公司
                <a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank">京ICP备18038674号</a>
            </div>
        </div>
    </div>
</template>
<style lang='scss' scoped>
.app-nfooter {
    background: #1A2334;
    color: #767B85;
    font-size: 12px;
    line-height: 22px;
}

.app-nfooter .footer-top-info {
    width:1248px;
    margin:0 auto;
    margin-bottom: 8px;
}
.app-nfooter  .footer-top {
    padding-top: 15px;
    margin-bottom: 8px;
    border-bottom: 1px solid #313949;
}
.app-nfooter  .footer-top .about {
    float: left;
    margin-right: 140px;
}
.app-nfooter  h4 {
    font-size: 14px;
    color: #fff !important;
    margin-top: 10px;
    margin-bottom: 10px;
}
.app-nfooter  .footer-top .about ul {
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
    margin-bottom: 10px;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.app-nfooter  a:hover {
    color: #fff;
}
.app-nfooter  a {
    color: #767B85;
    text-decoration: none;
}
.app-nfooter  .footer-top .contact {
    float: left;
    margin-right: 80px;
}
.app-nfooter  .footer-top .qrcode {
    float: left;
    margin-right: 60px;
    display: flex;
}
.app-nfooter  .footer-top .qrcode .qrcode-item {
    margin-left: 60px;
}
.app-nfooter  .footer-top .qrcode img {
    width: 100px;
    display: block;
}
.app-nfooter  .footer-link .footer-row {
    padding-left: 72px;
}

.app-nfooter  .footer-link .footer-row .footer-row-head {
    float: left;
    margin-left: -72px;
}
.app-nfooter  .footer-link .footer-row .footer-row-content .item {
    float: left;
    margin-right: 16px;
    position: relative;
}
.app-nfooter .footer-copy-bg {
    background: #111826;
    .footer-container{
        width: 1248px;
        margin: 0 auto;
        padding: 7px 0;
    }
}
.app-nfooter .footer-copy-bg img {
    opacity: 0.5;
    height: 35px;
    vertical-align: middle;
    margin: 0 10px;
}
</style>