import axios from 'axios'
//import router from "../router/index";  
import {refreshToken} from './loginApi'
import { ElMessage  } from 'element-plus'
import store from '../store'
//window.axiosCancel = []  // 全局定义一个存放取消请求的标识
//创建axios实例，后续所有通过实例发送的请求，都要受当前配置约束
const $http=axios.create({
    baseURL:'https://api.chinaecono.com',//'https://api.chinaecono.com',//'/api',//'https://8.131.245.178:36800',
    //timeout:2000,
    // headers: {'X-Custom-Header': 'foobar'}
});
// /*是否有请求正在刷新token*/
window.isRefreshing = false;
//添加请求拦截器
$http.interceptors.request.use(function(config){
  // //在发送请求之前做些什么，如token
  config.headers.Authorization='Bearer '+localStorage.getItem("accesstoken"); 
  // 添加取消标记
  // config.cancelToken = new axios.CancelToken(cancel => {
  //   window.axiosCancel.push({
  //     cancel
  //   })
  // })
  return config;
  
  // const token = localStorage.getItem("accesstoken");
  //       /*判断是否已登录*/
  //       if (token) {
  //           /*在请求头中添加token类型、token*/
  //           config.headers.Authorization='Bearer '+localStorage.getItem("accesstoken");
  //             /*判断是否正在刷新*/
  //             if (!window.isRefreshing) {
  //             /*把请求(token)=>{....}都push到一个数组中*/
  //             let retry = new Promise((resolve, reject) => {// eslint-disable-line no-unused-vars
  //               /*(token) => {...}这个函数就是回调函数*/
  //               subscribeTokenRefresh(token => {
  //                 config.headers.Authorization = "Bearer " + token;
  //                 /*将请求挂起*/
  //                 resolve(config);
  //               });
  //             });
  //             return retry;
  //         }
  //         return config;
  //       } else {
  //         /*未登录直接返回配置信息*/
  //         return config;
  //       }
},function(error){
  //对请求错误做些什么
  return Promise.reject(error);
})


//重试队列
let requests = []
//添加相应拦截器
$http.interceptors.response.use(function(response){
  console.log(response);
  if(!response)
  {
    ElMessage.success({
      message: '服务器错误！', 
    });   
    return; 
  } 
  if(response.data.code==30002)
  {  
      if (!window.isRefreshing) {
          window.isRefreshing = true;
          //调用刷新token的接口
          let refreshToknePara=localStorage.getItem("refreshToken"); 
          let currentUser=JSON.parse(localStorage.getItem('currentUser'));
          console.log(currentUser);
          let part={userId:currentUser?.userId.toString(),userName:currentUser?.userName, refreshToken:refreshToknePara};
          return refreshToken(part).then(res => {
              console.log('refresh',res);
              if(res && res.code==20000){
                  const { accessToken } = res.data
                  // 替换token
                  localStorage.setItem("accesstoken",res.data.accessToken); 
                  localStorage.setItem("refreshToken",res.data.refreshToken);
                  localStorage.setItem("currentUser",JSON.stringify(res.data.currentUser));   
                  response.headers.Authorization = `${accessToken}`
                  // token 刷新后将数组的方法重新执行
                  requests.forEach((cb) => cb(accessToken))
                  requests = [] // 重新请求完清空
                  return  $http(response.config)
              }
              else{
                  localStorage.removeItem("accesstoken");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("currentUser"); 
                  //router.push('/')
                  store.commit("setIsLoginVisable", true);
                  //return Promise.reject(res.message)
              }
          }).catch(err => {
            ElMessage.error({
              message: err?err:'请重新登录', 
            }); 
            localStorage.removeItem("accesstoken"); 
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("currentUser"); 
            //router.push('/')
            store.commit("setIsLoginVisable", true);
            return; 
          }).finally(() => {
              window.isRefreshing = false
          })
      }
      else {
          // 返回未执行 resolve 的 Promise
          return new Promise(resolve => {
              // 用函数形式将 resolve 存入，等待刷新后再执行
              requests.push(token => {
              response.headers.Authorization = `${token}`
              resolve($http(response.config))
              })
          })
      }
  } 
  else
  {
    //对相应数据做点什么
      let res=response.data;
      if(res.code && res.code!=20000){
          // ElMessage({
          //     message:res.message||'Error',
          //     type:'error',
          //     duration:5*1000
          // })
          //return Promise.reject(new Error(res.message||'Error'))
      }
      return res
  } 
  
},function(error){
  let message = "接口服务错误,请稍候再试.";
  if (error.response && error.response.status) {
    let statusCode = error.response.status;
    switch (statusCode) {
      case 401:
        message = "接口服务错误,原因:未授权的访问(没有权限或者登录已超时)";
        break;
      case 500:
        message = "接口服务错误,原因:[" + error.response.statusText + "]";
        break;
      case -1:
        message = "网络出错,请检查你的网络或者服务是否可用<br />请求地址:[" + error.config.url + "]";
        break;
    }
  }
  ElMessage.error({
    message: message, 
  }); 
  return Promise.reject(error);
});

export default $http