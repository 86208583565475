//import { forEach } from "core-js/core/array";

//首页查询分类
export const qcList=[{id:1,name:"查地区",desc:'输入地区',isCheck:true},
            {id:2,name:"查指标",desc:'输入指标项名，如"地区生产总值"',isCheck:false}];
//高德地图web服务api的key
export const amapKey='789320163fcc45a0f4591dfd34c0e318'
export const roleColors=['#F5F5F5','#C0C0C0','#DAA520','#D2691E'];
export const colors=['#002FA7','#73C0DE','#5F9EA0','#87CEFA','#008B8B','#20B2AA','#B0E0E6','#00BFFF','#4169E1','#1E90FF','#90EE90','#228B22','#6A5ACD','#9370DB','#9932CC'];
//图标列表
export const iconAry=['ec-icon-fill','ec-icon-xiaofei','ec-icon-group-fill','ec-icon-user','ec-icon-lightning','ec-icon-relation',
'ec-icon-resource','ec-icon-theme','ec-icon-agro','ec-icon-edu','ec-icon-livelihood','ec-icon-industry','ec-icon-housing','ec-icon-finance',
'ec-icon-analysis','ec-icon-label','ec-icon-data','ec-icon-pie','ec-icon-text','ec-icon-organization','ec-icon-sport',
'ec-icon-fix','ec-icon-balance','ec-icon-send','ec-icon-ship','ec-icon-plane','ec-icon-stage','ec-icon-duibivs','ec-icon-xinwen',
'ec-icon-list','ec-icon-ep','ec-icon-fenxi','ec-icon-xinwen'];
//用0补全字符串
export function PrefixInteger(num, length) {
    if (num == '0') {
        return '100000'
    }
    else {
        return (num + Array(length).join('0')).substr(0, length);
    }
}
 /**
 * 十六进制颜色转换为带透明度的颜色
 * @param _color 十六进制颜色
 * @param _opactity 透明度
 * @returns {string} rgba
 */
export function hexToRGBA(_color, _opacity) {
  let sColor = _color.toLowerCase()
  // 十六进制颜色值的正则表达式
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return 'rgba(' + sColorChange.join(',') + ',' + _opacity + ')'
  }
  return sColor
}
/**
 * 获取当前登录的浏览器
 */
export function getBrowserInfo(){
  var agent = navigator.userAgent.toLowerCase() ;
  console.log("agent:", agent);
  var arr = [];
  var system = agent.split(' ')[1].split(' ')[0].split('(')[1];
  arr.push(system);
  var regStr_edge = /edge\/[\d.]+/gi;
  var regStr_ie = /trident\/[\d.]+/gi ;
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi ;
  var regStr_saf = /safari\/[\d.]+/gi ;
  var regStr_opera = /opr\/[\d.]+/gi;
  //IE
  if(agent.indexOf("trident") > 0){
    arr.push(agent.match(regStr_ie)[0].split('/')[0]);
    arr.push(agent.match(regStr_ie)[0].split('/')[1]);
    return arr;
  }
  //Edge
  if(agent.indexOf('edge') > 0){
    arr.push(agent.match(regStr_edge)[0].split('/')[0]);
    arr.push(agent.match(regStr_edge)[0].split('/')[1]);
    return arr;
  }
  //firefox
  if(agent.indexOf("firefox") > 0){
    arr.push(agent.match(regStr_ff)[0].split('/')[0]);
    arr.push(agent.match(regStr_ff)[0].split('/')[1]);
    return arr;
  }
  //Opera
  if(agent.indexOf("opr")>0){
    arr.push(agent.match(regStr_opera)[0].split('/')[0]);
    arr.push(agent.match(regStr_opera)[0].split('/')[1]);
    return arr;
  }
  //Safari
  if(agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0){
    arr.push(agent.match(regStr_saf)[0].split('/')[0]);
    arr.push(agent.match(regStr_saf)[0].split('/')[1]);
    return arr;
  }
  //Chrome
  if(agent.indexOf("chrome") > 0){
    arr.push(agent.match(regStr_chrome)[0].split('/')[0]);
    arr.push(agent.match(regStr_chrome)[0].split('/')[1]);
    return arr;
  }
  // else{
  //   arr.push('请更换主流浏览器,例如chrome,firefox,opera,safari,IE,Edge!')
  //   return arr;
  // }
}
export function isMobile(){
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	return flag;
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
 export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string')) {
        if ((/^[0-9]+$/.test(time))) {
          // support "1548221490638"
          time = parseInt(time)
        } else {
          // support safari
          // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
          time = time.replace(new RegExp(/-/gm), '/')
        }
      }
  
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
      const value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
      return value.toString().padStart(2, '0')
    })
    return time_str
  }
  export function parseTimeOfCycle(time,cycle) {
    if (!time) {
      return null
    }
    let time_str='';
    let c_time=new Date(time);
    if(cycle.toString()=='1'){//年度
      time_str=c_time.getFullYear().toString();
    }
    else if(cycle.toString()=='2'){//季度
      time_str=c_time.getFullYear()+'年'+(c_time.getMonth()/3+1)+'季';//new Date(time).getFullYear()+'-'+(new Date(time).getMonth()/3+1);
    }
    else if(cycle.toString()=='3'){//月度
      time_str=c_time.getFullYear()+'年'+(c_time.getMonth()+1)+'月';
    }
    else if(cycle.toString()=='4'){//旬
      let tendays='';
      if(c_time.getDate()==1){
        tendays='上旬';
      }
      else if(c_time.getDate()==11){
        tendays='中旬';
      }
      else {
        tendays='下旬';
      }
      time_str=c_time.getFullYear()+'年'+(c_time.getMonth()+1)+'月'+tendays;

    }
    else if(cycle.toString()=='5'){//周
      time_str=time;
      let w=c_time.getDay();//星期数
      let d=c_time.getDate();
      let week=Math.ceil((d+6-w)/7);
      time_str=c_time.getFullYear()+'年'+(c_time.getMonth()+1)+'月'+week+'周';

    }
    else{//天
      time_str=time;
    }
    return time_str;
  }
  /**
   * 时间列表
   * @param {*} cycle 
   * @returns 
   */
  export function getDateList(cycle) {
    //console.log(cycle);
    let dateList=[];
    let date=new Date();
    if(cycle.toString()=='1'){//年度，近十年的时间列表
      const year = date.getFullYear();
      //console.log(year);
     
      for (let index = 10; index > 0; index--) {
        dateList.push((year-index)+'-01-01');
      }
    }
    else if(cycle.toString()=='2'){//季度，近八个季度的时间列表
      
    }
    else if(cycle.toString()=='3'){//月度，近12个月的时间列表
      date.setMonth(date.getMonth() - 12)//前12月的时间
      for (let index = 12; index > 0; index--) {
        dateList.push(date.getFullYear()+'-'+(date.getMonth()<9?'0':'')+(date.getMonth()+1)+'-01');
        date.setMonth(date.getMonth() + 1);
      }
    }
    else if(cycle.toString()=='4'){//旬,近一年的时间列表
      date.setMonth(date.getMonth() - 12)//前12月的时间
      for (let index = 12; index > 0; index--) {
        dateList.push(date.getFullYear()+'-'+(date.getMonth()<9?'0':'')+(date.getMonth()+1)+'-01');
        dateList.push(date.getFullYear()+'-'+(date.getMonth()<9?'0':'')+(date.getMonth()+1)+'-11');
        dateList.push(date.getFullYear()+'-'+(date.getMonth()<9?'0':'')+(date.getMonth()+1)+'-21');
        date.setMonth(date.getMonth() + 1);
      }
    }
    else if(cycle.toString()=='5'){//周
    
    }
    else{//天
      
    }
    return dateList;
  }
  /** 去抖动
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result
  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
//返回数组的最大值、最小值、平均值、中位数
export function analysisOfData(ary){
  let maxVal='-';
  let minVal='-';
  let avgVal='-';
  let middleVal='-';
  //最大值
    maxVal.value=Math.max(...ary);//es6
  //最小值
  minVal.value=Math.min(...ary);
  //平均值
  //1、先求和
  var sumVal = ary.reduce(function(pre, curr) {
      return pre + curr;
  });
  //2、求个数，平均=和/个数
  avgVal.value=(sumVal/ary.length).toFixed(2);
  
  //中位数
  let newArr = ary.sort(function(a,b){return a-b})
  console.log(ary)
  let len=newArr.length
  console.log(len%2)
  if(len%2===0){
      middleVal.value=(newArr[parseInt(len/2-1)]+newArr[len/2])/2
  }else{
      middleVal.value=newArr[parseInt(len/2)]
  }
  return {maxVal,minVal,avgVal,middleVal}

}
//将base64转blob
export function base64ToBlob(code) {
  var arr = code.split(',')
  // 图片格式
  var mime =  arr[0].match(/:(.*?);/)[1]
  // 获取，之后的base64的编码
  var bstr = window.atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export const  setCookie=(cName, value, expiredays)=> {
  var exdate = new Date()
  exdate.setDate(exdate.getDate() + expiredays)
  
  document.cookie = cName + '=' + decodeURIComponent(value) +
  ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
}
export const getCookie = (key)=> {
  if (document.cookie.length > 0) {
    var start = document.cookie.indexOf(key + '=')
    if (start !== -1) {
        start = start + key.length + 1
        var end = document.cookie.indexOf(';', start)
        if (end === -1) end = document.cookie.length
        return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}
/**
 * 将两个输入数组合并为一个数组
 */
export const zip = ([x,...xs], [y,...ys]) => {
  if (x === undefined || y === undefined)
    return [];
  else
    return [[x,y], ...zip(xs, ys)];
}
/**
 * 根据某个属性将数组分组
 */
 export function getList(list,field) {
  const map = new Map()
  list.forEach((item, index, arr) => {
    if (!map.has(item[field])) {
      map.set(
        item[field],
        arr.filter(a => a[field] == item[field])
      )
    }
  })
  return Array.from(map).map(item =>{ 
    return{
      key:item[0],
      item:item[1].map(t=>t.id)
    }
  });
  //return Array.from(map).map(item => [...item[1]])
}
//树结构查询
export const treeFilter=function (tree, func) {
  // 使用map复制一下节点，避免修改到原树
  return tree.map(node => ({ ...node })).filter(node => {
    node.children = node.children && treeFilter(node.children, func)
    return func(node) || (node.children && node.children.length)
  })
}
//树结构查找节点
export const treeFind= function (tree, func) {
  for (const data of tree) {
    if (func(data)) return data
    if (data.children) {
      const res = treeFind(data.children, func)
      if (res) return res
    }
  }
  return null
}
  