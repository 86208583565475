<template>
    <div class="tyc-header " >
        <div class="tyc-header-container">
            <a href="/" class="tyc-header-logo">
                <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" height="35" alt="">
                <span>经济运行平台</span>
            </a>
            <div class="tyc-header-right">
                <div class="tyc-header-nav">     
                    <div class="tyc-header-nav-item">
                        <el-link :underline="false" href="/#/register">免费注册</el-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="user-login">
        <div class="head-bg">
        </div>
        <div class="login-area">
            <div class="app-login_wrapper app-login-new">
                <Login class="right-c"></Login>
            </div>
        </div>
    </div>
    <FooterNew></FooterNew>
</template>
<script>
import FooterNew from '@/components/FooterNew.vue'
import Login from '@/components/Login.vue'
import { ref } from '@vue/reactivity';
export default {
    components:{
        FooterNew,
        Login
    },
    setup() {
        let loginType=ref(1);//登录方式：1:快捷登录,0:密码登录
        let loginForm=ref({
        userName:'',
        password:'',
        tel:'',
        verifyCode:'',
    })
        //设置登录类型
        const SetLoginType=(type)=>{
            loginType.value=type;
        }
        return{
            loginType,
            loginForm,
            SetLoginType
        }
    },
}
</script>