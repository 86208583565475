import { createRouter, createWebHashHistory } from 'vue-router'
//import Home from '@/views/Home.vue'
//import Index from '@/views/Index.vue'
//import Login from '@/views/login.vue'
import { start, close } from '@/utils/nprogress';
import routes from "./routers";
import store from '../store'
import {getAppMenuList} from '../http/homeApi'
//import {getUserRightFun} from '../http/basicsApi'

let staticRouters = ["index", "page404", "login", "register","forgetPassword",'aboutus','contact','disclaimer','feedback','privacyPolicy','userContent','userHelp']

// const routes_ = [
//   //淄博市-调整 2023-04-17
//   {
//     path: '/',
//     name: 'Index',
//     //component: Home,
//     component: Index,
//     hidden:false,
//     meta:{
//       isShow:false,
//       title:'首页'
//     }
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     component: Login,
//     hidden:false,
//     meta:{
//       isShow:false,
//       title:'登录'
//     }
//   },
  
//   {
//     path: '/home',
//     //淄博市-调整 2023-04-17
//     //path: '/',
//     name: 'Home',
//     //redirect: '/econo-dev/index',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: Home,
//     meta:{
//       isShow:true,
//       title:'首页'
//     }
//   },
//   {
//     path: '/econo-dev',
//     name: 'econodev',
//     //redirect: '/econo-dev/commonTheme',
//     component: () => import(/* webpackChunkName: "econoDev" */ '../views/econo-dev/index.vue'),
//     meta:{
//       isShow:true,
//       title:'区域态势'
//     },
//     children: [{
//       path: '',
//       name: 'commonTheme',
//       component: () => import(/* webpackChunkName: "econoDev" */ '../views/econo-dev/commonTheme.vue'),
//       // meta:{
//       //   isShow:true,
//       //   title:'区域态势'
//       // }
//     },{
//       path: 'difference',
//       name: 'difference',
//       component: () => import(/* webpackChunkName: "econoDev" */ '../views/econo-dev/difference.vue'),
//       meta:{
//         isShow:false,
//         title:'区域比较'
//       }
//     },
//     {
//       path: 'myTheme',
//       name: 'myTheme',
//       component: () => import(/* webpackChunkName: "econoDev" */ '../views/econo-dev/myTheme.vue'),
//       meta:{
//         isShow:false,
//         title:'我的主题'
//       }
//     }]
//   },
//   {
//     path:'/industry',
//     name:'industry',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "industry" */ '../views/industry/index.vue'),
//     meta:{
//       isShow:true,
//       title:'行业趋势'
//     },
//   },
//   {
//     path: '/ep',
//     name: 'ep',
//     //redirect: '/ep/epList',
//     component: () => import(/* webpackChunkName: "ep" */ '../views/ep/index.vue'),
//     meta:{
//       isShow:true,
//       title:'企业画像'
//     },
//     children: [{
//       path:'',
//       name:'epList',
//       component:()=>import(/* webpackChunkName: "ep" */ '../views/ep/epList.vue'),
//       meta:{
//         isShow:false,
//         title:'企业查询'
//       },
//     }, 
//     {
//       path:'epAnalysis/:id',
//       name:'epAnalysis',
//       component:()=>import(/* webpackChunkName: "ep" */ '../views/ep/epAnalysis.vue'),
//       meta:{
//         isShow:false,
//         title:'企业分析'
//       },
//     }]
//   },
//   {
//     path: '/industryChain',
//     name: 'industryChain',
//     //redirect: '/industryChain/list',
//     component: () => import(/* webpackChunkName: "industryChain" */ '../views/industryChain/index.vue'),
//     meta:{
//       isShow:false,
//       title:'产经智链'
//     },
//     children: [{
//       path:'',
//       name:'industryChainList',
//       component:()=>import(/* webpackChunkName: "industryChain" */ '../views/industryChain/list.vue'),
//       meta:{
//         isShow:false,
//         title:'产业链列表'
//       },
//     },
//     {
//       path:'info/:id',
//       name:'IndustryChainInfo',
//       component:()=>import(/* webpackChunkName: "industryChain" */ '../views/industryChain/info.vue'),
//       meta:{
//         isShow:false,
//         title:'产业链信息'
//       },
//     }]
//   },
//   {
//     path: '/search',
//     name: 'search',
//     //redirect: '/econo-dev/commonTheme',
//     component: () => import(/* webpackChunkName: "search" */ '../views/search/index.vue'),
//     meta:{
//       isShow:false,
//       title:'指标搜索'
//     },
//     children: [ {
//       path: '',
//       name: 'econoSearch',
//       component: () => import(/* webpackChunkName: "search" */ '../views/search/econoSearch.vue'),
//       meta:{
//         isShow:false,
//         title:'宏观经济搜索'
//       }
//     },{
//       path: 'ecoIndustrySearch',
//       name: 'ecoIndustrySearch',
//       component: () => import(/* webpackChunkName: "search" */ '../views/search/ecoIndustrySearch.vue'),
//       meta:{
//         isShow:false,
//         title:'行业经济搜索'
//       }
//     },{
//       path: 'microEcoSearch',
//       name: 'microEcoSearch',
//       component: () => import(/* webpackChunkName: "search" */ '../views/search/microEcoSearch.vue'),
//       meta:{
//         isShow:false,
//         title:'微观经济搜索'
//       }
//     },{
//       path: 'ecoArticleSearch',
//       name: 'ecoArticleSearch',
//       component: () => import(/* webpackChunkName: "search" */ '../views/search/ecoArticleSearch.vue'),
//       meta:{
//         isShow:false,
//         title:'经济舆情搜索'
//       }
//     }]
//   },
//   {
//     path:'/people',
//     name:'people',
//     //redirect: '/people/collections',
//     component:()=>import(/* webpackChunkName: "people" */ '../views/people/index.vue'),
//     meta:{
//       isShow:false,
//     },
//     children:[
//       //提供一个空的嵌套路径
//       {
//         path: "",//子路由前不需要加斜杠 /
//         name: "collections",
//         component:()=>import(/* webpackChunkName: "people" */ '../views/people/collections.vue'),
//         //props: true,
//         meta: { title: "收藏" },
//       },
//       {
//         path: "areaeco",//子路由前不需要加斜杠 /
//         name: "areaeco",
//         component:()=>import(/* webpackChunkName: "people" */ '../views/people/areaeco.vue'),
//         //props: true,
//         meta: { title: "通用区域经济" },
//       },
//       {
//         path: "areaecoSelf",//子路由前不需要加斜杠 /
//         name: "areaecoSelf",
//         component:()=>import(/* webpackChunkName: "people" */ '../views/people/areaecoSelf.vue'),
//         //props: true,
//         meta: { title: "个性区域经济" },
//       },
//     ]
//   },
//   {
//     path:'/pdf',
//     name:'pdf',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "pdf" */ '../views/pdf.vue'),
//     meta:{
//       isShow:false,
//       title:'pdf浏览'
//     },
//   },
//   {
//     path:'/link',
//     name:'Link',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "Link" */ '../views/link.vue'),
//     meta:{
//       isShow:false,
//       title:'跳转页'
//     },
//   },
//   {
//     path:'/register',
//     name:'register',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "register" */ '../views/register.vue'),
//     meta:{
//       isShow:false,
//       title:'注册'
//     },
//   },
//   {
//     path:'/forgetPassword',
//     name:'forgetPassword',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "forgetPassword" */ '../views/forgetPassword.vue'),
//     meta:{
//       isShow:false,
//       title:'找回密码'
//     },
//   },
//   {
//     path:'/help/aboutus',
//     name:'aboutus',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/aboutus.vue'),
//     meta:{
//       isShow:false,
//       title:'关于我们'
//     },
//   },
//   {
//     path:'/help/contact',
//     name:'contact',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/contact.vue'),
//     meta:{
//       isShow:false,
//       title:'联系我们'
//     },
//   },
//   {
//     path:'/help/disclaimer',
//     name:'disclaimer',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/disclaimer.vue'),
//     meta:{
//       isShow:false,
//       title:'免责声明'
//     },
//   },
//   {
//     path:'/help/userHelp',
//     name:'userHelp',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/userHelp.vue'),
//     meta:{
//       isShow:false,
//       title:'用户帮助'
//     },
//   },
//   {
//     path:'/help/feedback',
//     name:'feedback',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/feedback.vue'),
//     meta:{
//       isShow:false,
//       title:'意见反馈'
//     },
//   },
//   {
//     path:'/help/privacyPolicy',
//     name:'privacyPolicy',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/privacyPolicy.vue'),
//     meta:{
//       isShow:false,
//       title:'隐私权政策'
//     },
//   },
//   {
//     path:'/help/userContent',
//     name:'userContent',
//     //redirect: '/industry/portrait',
//     component:()=>import(/* webpackChunkName: "help" */ '../views/help/userContent.vue'),
//     meta:{
//       isShow:false,
//       title:'用户协议'
//     },
//   },
// ]
//console.log([...routes]);
const router = createRouter({
  history: createWebHashHistory(),
  routes:[...routes]
})

// 页面渲染成功之后，展示进度条（实际效果：Mac的Chrome就是在页面顶部有条2px左右的进度条）
router.beforeEach(async (to, from,next) => {// eslint-disable-line no-unused-vars
  start();
  //const token =localStorage.getItem('accesstoken');
  //console.log(token);
  if (to.name && staticRouters.filter(t=>t.toLowerCase()==to.name.toLowerCase()).length>0) {
    // 未登录且要跳转的页面不是登录页
    next()
    close();
  }
  // else if(!token){
  //   console.log('sss');
  //   next('/')
  //   close();
  // }
  else {
    if (store.state.menuRouteLoaded) {
      next()
      
    } 
    else{
      try {
        // let navMenuData=localStorage.getItem("navMenuData");
        // if(navMenuData){
        //   navMenuData=JSON.parse(navMenuData);
        //   console.log('加载菜单','localStorage')
        //   //添加完路由后跳转添加的页面却404
        //   // 1. 先删掉404, 按照文档来说后添加会将其覆盖,保险起见还是删除吧
        //   router.removeRoute("page404");
        //   // 2. 挂载对应的路由模块
        //   let list=formatMenu(navMenuData);
        //   list.forEach(el => {
        //     router.addRoute(el.name, el);
        //   });
        //   // 3. 挂载404模块
        //   router.addRoute( {
        //     path: '/:catchAll(.*)',
        //     name: 'page404',
        //     component: () =>  import(/* webpackChunkName: "404" */ '../views/404.vue')
        //   });
        //   store.commit("setMenuRouteLoadStatus", true);
        //   next({ ...to, replace: true })
        // }
        // else{
        //   console.log('加载菜单','服务器')
          // 本地localStorage获取不到，从服务器端读取
          // let currentUser=JSON.parse(localStorage.getItem("currentUser"));
          // console.log(currentUser);
          let res=await getAppMenuList();  
          if(res.code==20000 && res.data)
          {
            //添加完路由后跳转添加的页面却404
            // 1. 先删掉404, 按照文档来说后添加会将其覆盖,保险起见还是删除吧
            router.removeRoute("page404");
            // 2. 挂载对应的路由模块
            let list=formatMenu(res.data);
            list.forEach(el => {
              router.addRoute(el.name,el);
            });
            // 3. 挂载404模块
            router.addRoute( {
              path: '/:catchAll(.*)',
              name: 'page404',
              component: () =>  import(/* webpackChunkName: "404" */ '../views/404.vue')
            });
            //localStorage.setItem("navMenuData", JSON.stringify(res.data));
            // 设置菜单已加载状态
            store.commit("setMenuRouteLoadStatus", true);
            next({ ...to, replace: true })
          }
        //}
      } catch (error) {
        //await store.dispatch('user/resetToken')
     
        next(`/`)
        close();
      }
    }
  }
})

// 页面加载成功之后，关闭进度条
router.afterEach(to => {// eslint-disable-line no-unused-vars
  close();
})

export const formatMenu = (list) => {
  let res=[];
  list.forEach(item => {
    let route_={
      path: `${item.path}`,
      name: item.name,
      component: () => import(`@/views${item.children && item.children.length?item.path+"/index": item.path}.vue`),
      meta:{
        menuId:item.id,
        title:item.title,
        icon:item.icon,
        sort:item.sort,
        permission:item.permission
      }
    };
    if(item.children && item.children.length){//默认路由
      let fiterAry=item.children.filter(t=>t.isDefaultRouter==1);
      if(fiterAry.length>0){
        route_={...route_,...{redirect: fiterAry[0].path}}
      }
    }
    if (item.children && item.children.length) {
      route_.children = formatMenu(item.children)
    }
    res.push(route_)
  })
  return res
}

export default router
