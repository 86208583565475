<template>
<!-- <div v-if="isShow"> -->
  

<!-- router-view的key属性解决同一路由不同参数页面刷新问题 -->
      <router-view v-if="isRouterAlive"  :key="$route.fullPath"></router-view>
      <!-- </div> -->
      <el-dialog
        v-model="$store.state.rightVisable"
        title=""
        :width='700'
        :center='true'
        custom-class="auth-dialog"
        destroy-on-close 
        :close-on-click-modal='false'>
        <div>
          <RightLimit  :url='$store.state.exampleUrl' ></RightLimit>
          <!-- <div class='auth_body_title'>
            <span style="display:block;text-align:center;" >无权限操作此功能 
              <el-link :underline="false" href="/#/help/auth" @click.prevent="push" >查看详情 <i class="iconfont ec-icon-arrow-right-bold" style="margin-left:-8px;"></i> </el-link>
            </span>
          </div>
         <div style="padding: 25px 25px 30px;">
            <div class="dpr21">
              <span style="display:block;text-align:center;color:#e6a23c;font-weight: 600; font-size:20px;margin-top:40px;">权限升级，享超值服务</span>
              <span style="display:block;text-align:center;color:#e6a23c;margin-top: 5px;">大数据融合,多维度分析</span>
              <div style="margin-top: 20px;width: 120px;position: absolute;right: 20px;top: 0px;">
                <el-image class='pic-wechat' src="https://data.chinaecono.com/images/wx_code.jpg?t=1697007893" fit="fill" />
                <div style="text-align:center;">
                  <span >扫一扫 立即咨询</span>
                </div>
                
              </div>
            </div>
            
            <el-table :data="roleList" border  stripe style="width: 100%">
              <el-table-column prop="name" label="会员" width="180" />
              <el-table-column prop="desc" label="描述"  />
              <el-table-column prop="opt" label="开通方式" width="180"/>
            </el-table>
          </div> -->
        </div>
      </el-dialog>
</template>

<script>
import { ref } from '@vue/reactivity';
import { nextTick, provide } from '@vue/runtime-core';
import {useRegionList} from '@/http/homeApi'
//import { useRouter } from 'vue-router';
import RightLimit from '@/components/RightLimit.vue'
//import {refreshToken} from '@/http/loginApi'
//import store from '@/store';
export default {
  name: 'App',
  components:{
    RightLimit
  },
  setup(){
    //const router=useRouter();
    // 局部组件刷新
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);
    //检查用户登录是否过期
   
    //获取所有的地区列表
    const {regionList,alphabet}= useRegionList();// eslint-disable-line no-unused-vars
    provide('regionList',ref(regionList));
    provide('alphabet',ref(alphabet));
    /*
    let roleList=reactive([{name:'大众会员',desc:'官方通用指标查看,行业规模、行业效益、行业风险等分析，精准监控数千万家企业',opt:'已开通'},
    {name:'银会员',desc:'支持定量指标下载、pdf格式的多种报告下载，产经智链应用等更多服务',opt:'咨询购买'},
    {name:'金会员',desc:'更大量的满足客户的报告、图表等的下载，企业标签的在线编辑， 独家应用尽情看',opt:'咨询购买'},
    {name:'钻石会员',desc:'专属社群，一对一客服服务，海量数据轻松下载，提供纸质版报告、经济沙龙',opt:'咨询购买'}])
    
    const push=()=>{
      store.commit("setIsRightVisable", false);
      router.push('/help/auth')
    }
    */
    return{
      isRouterAlive,
      //roleList,
      // push
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.dpr21{
  padding: 20px;
  background-image: linear-gradient(rgba(230,162,60,0.2), rgba(230,162,60,0.05));
  border-radius: 16px;
  margin-bottom: 20px;
  position: relative;
  height: 180px;
}
.auth_body_title{
  background-color:$specialtext-color;
  color: #fff;
  padding: 20px 0;
  a{
    color: rgb(230, 162, 60);
    &:hover {
      color: rgb(230, 162, 60);
  }
  }
}
.pic-wechat{
  width: 120px;
}
</style>

