import { ref } from '@vue/reactivity';
import axios from 'axios'
import { ElMessage  } from 'element-plus'
//import {amapKey} from '@/utils/index.js'
//创建axios实例，后续所有通过实例发送的请求，都要受当前配置约束
const $http=axios.create({
    baseURL:'https://api.chinaecono.com',//'https://api.chinaecono.com',//'/api',//'https://8.131.245.178:36800',
    //timeout:2000,
    // headers: {'X-Custom-Header': 'foobar'}
});
//添加请求拦截器
$http.interceptors.request.use(function(config){
    //在发送请求之前做些什么，如：token
    return config;
},function(error){
    //对请求错误做些什么
    return Promise.reject(error);
});
//添加响应拦截器
$http.interceptors.response.use(function(response){
  //console.log(response);
    //对响应数据做点什么
    let res=response.data;
    if (res.code !== 20000) {
        ElMessage ({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        return res
      }
},function(error){
    return Promise.reject(error);
});

//地区列表查询
export const useRegionList=()=>{
    let regionList= ref(null);
    let alphabet=ref(null);
    $http.get(`/api/Economy/GetCityList`).then((res)=>{
        if(res.code==20000){
            regionList.value=res.data;
            let arr = regionList.value.map((item)=>{
              return item.chineseToEn
              });
            alphabet.value = [...new Set(arr)]; 
        }
    });
    return {regionList,alphabet}
}

// //根据名称获取地区列表
// export const getRegionList=(data)=>{
//   return  $http.post(`/api/Economy/GetRegionList`,data)
// }



// //手机号验证，是否存在用户
// export const isUserExist=(phone,verifyCode)=>{
//   return $http.get(`/api/Business/IsUserExist?phone=${phone}&verifyCode=${verifyCode}`);
// }
//注册用户
export const registerUser=(data)=>{
  return $http.post(`/api/Business/RegisterUser`,data);
}
//找回密码
export const setPassword=(data)=>{
  return $http.post(`/api/Business/SetPassword`,data);
}

//发送邮件
export const messageMail=(data)=>{
  return $http.post(`/api/Business/MessageMail`,data);
}
//获取应用平台的菜单列表
export const getAppMenuList=()=>{
  return $http.get(`/api/Business/GetAppMenuList?appId=20`);
}
//随机获取指标列表
export const getRandomItemList=()=>{
  return $http.get(`/api/EcoAnalysis/GetRandomItemList`);
}
//搜索查询指标列表
export const getItemList=(data)=>{
  return $http.post(`/api/EcoAnalysis/GetItemList`,data);
}
//根据IP获取地理位置
export const getCurRegionCode= async(ip)=>{// eslint-disable-line no-unused-vars

  // let code=localStorage.getItem('regionCode');
  // if(!code)
  // {
    let code='';
    //let res=await axios.get(`https://restapi.amap.com/v3/ip?ip=${ip}&key=${amapKey}`)
    // if(res.data.status=="1" && res.data.infocode=='10000' && res.data.adcode){
    //   code=res.data.adcode;
    // }
    let res=await $http.get(`/api/Business/GetUserAddr?ip=${ip}`);
    console.log(res);
    if(res.code==20000 && res.data.length>0){
      code=res.data[0].code;
    }
    else {
      code='110000';
    }
  //   localStorage.setItem('regionCode',code)
  // }

  // 淄博市 ——调整   2023-04-17
  //let code='370300';
  return {code};
}