//基础信息的接口
import {ref} from 'vue'
import $http from './index.js'
import {getBrowserInfo} from '@/utils/index'
import store from '../store'
let browser=getBrowserInfo();//浏览器
   
//判断用户是否登录
export const getIsLogin=()=>{
  return $http.get(`/api/Business/IsLogin`);
}
//获取用户菜单列表
/*
* code:菜单或按钮的编码
* type：类型，0：菜单，1：按钮
* menuId：菜单ID，当type=1时，要传入menuId
* rightType:权限类型，0：弹出权限的对话框，1：不弹出
*/
export const getUserRightFun =async (code,type,menuId,rightType=0)=>{
  console.log(rightType);
  let isRight=false;
  let currentUser=JSON.parse(localStorage.getItem("currentUser"));
  //console.log(currentUser);
  if(currentUser){
    let param={userId:currentUser.userId,appId:20}
    let res= await $http.post(`api/Business/GetUserMenuList`,param);
    //console.log(res);
    if(res.code==20000 && res.data){
      let obj=null;
      if(type==0){//菜单
        obj=res.data.find(t=>t.type==type && t.code.toLowerCase()==code.toLowerCase())
      }
      else {
        obj=res.data.find(t=>t.type==type && t.code.toLowerCase()==code.toLowerCase() && t.parentId==menuId)
      }
      if(!obj && rightType==0){
        console.log('权限', code,type,menuId,rightType);
        store.commit("setIsRightVisable", [true,type==0?code+'.png':'']);
      }
      if(obj){
        isRight=true;
      }
    }
    // else{
    //   store.commit("setIsRightVisable", true);
    // }
  }
  else{
    store.commit("setIsLoginVisable", true);//打开登录对话框
  }
  return isRight;
}

//地区列表查询
export const useRegionList=()=>{
    let regionList= ref(null);
    let alphabet=ref(null);
    $http.get(`/api/Economy/GetCityList`).then((res)=>{
        if(res.code==20000){
            regionList.value=res.data;
            let arr = regionList.value.map((item)=>{
              return item.chineseToEn
              });
            alphabet.value = [...new Set(arr)]; 
        }
    });
    return {regionList,alphabet}
}
//地区详细查询
export const getRegionInfo=(regionCode)=>{
  return  $http.get(`/api/Economy/GetRegionInfo?regionCode=`+regionCode)
}
//获取地区列表
export const getAllRegionList=()=>{
  return  $http.get(`/api/Economy/GetCodeRegionList`)
}
//根据条件获取地区列表
export const getCodeRegionList=(data)=>{
  return  $http.post(`/api/Economy/GetCodeRegionList`,data)
}
//获取行业下级分类
export const getNextCodeNicList=(prevCode)=>{
  return  $http.get(`/api/Enterprise/GetNextCodeNicList?prevCode=`+prevCode)
}
//根据行业编号获取行业列表
export const getCodeNicList=(data)=>{
  return  $http.post(`/api/Enterprise/GetCodeNicList`,data)
}
/****************用户***************/
//获取用户信息
export const getUserInfo=(userId)=>{
  return  $http.get(`/api/Business/GetUserInfo?userId=${userId}`)
}
//获取用户角色信息
export const getRoleList=(data)=>{
  return  $http.post(`/api/Business/GetRoleList`,data)
}

//设置用户头像
export const setUserPicUrl=(data)=>{
  return  $http.post(`/api/Business/SetUserPicUrl`,data)
}
//设置密码
export const updatePassword=(data)=>{
  return  $http.post(`/api/Business/UpdatePassword`,data)
}
//修改用户信息
export const changeUser=(data)=>{
  return  $http.post(`/api/Business/ChangeUser`,data)
}
//修改用户信息
export const uploadPic=(data,type)=>{
  return  $http.post(`/api/Business/UploadPic?type=${type}`,data)
}


//记录用户操作日志
export const addUserLog=(menuCode,pageurl,operation,param)=>{
    let params={appId:20,
      ip:localStorage.getItem("ip"),
      browserName:browser[1]+' '+browser[2],
      deviceType:browser[0].replace(';',''),
      regionCode:localStorage.getItem("curRegionCode"),
      pageUrl:pageurl,
      menuCode:menuCode,
      operation:operation,
      operationParams:param
  }
  //console.log(params);
  return  $http.post(`/api/Business/AddUserLog`,params)
}
//获取指标项是否已收藏
export const getItemIsFavorite=(data)=>{
  return  $http.post(`/api/Business/GetItemIsFavorite`,data)
}
//添加用户收藏夹
export const addUserFavorites=(data)=>{
  return  $http.post(`/api/Business/AddUserFavorites`,data)
}
//修改收藏夹
export const changeUserFavorites=(data)=>{
  return  $http.post(`/api/Business/ChangeUserFavorites`,data)
}
//删除收藏夹
export const delUserFavorites=(data)=>{
  return  $http.post(`/api/Business/DelUserFavorites`,data)
}

//添加收藏夹指标项
export const addItemOfFavorites=(data)=>{
  return  $http.post(`/api/Business/AddItemOfFavorites`,data)
}

//删除收藏夹指标项
export const delItemOfFavorites=(data)=>{
  return  $http.post(`/api/Business/DelItemOfFavorites`,data)
}
//获取用户收藏夹的数量
export const getNumOfUserFavorites=(userId)=>{
  return  $http.get(`/api/Business/GetNumOfUserFavorites?userid=${userId}`)
}

//获取用户收藏夹
export const getUserFavorites=(userId)=>{
  return  $http.get(`/api/Business/GetUserFavorites?userid=${userId}`)
}
//获取收藏夹指标列表
export const getFavList=(data)=>{
  return  $http.post(`/api/Business/GetFavList`,data)
}
///*********************/
//用户自定义区域经济——通用
//获取用户区域经济主题的数量
export const getNumOfUserEcoTheme=(userId,type)=>{
  return  $http.get(`/api/Business/GetNumOfUserEcoTheme?userid=${userId}&type=${type}`)
}
//获取用户区域经济主题列表
export const getUserEcoTheme=(userId,type)=>{
  return  $http.get(`/api/Business/GetUserEcoTheme?userid=${userId}&type=${type}`)
}
//添加用户区域经济主题
export const addUserEcoTheme=(data)=>{
  return  $http.post(`/api/Business/AddUserEcoTheme`,data)
}
//修改用户区域经济主题
export const changeUserEcoTheme=(data)=>{
  return  $http.post(`/api/Business/ChangeUserEcoTheme`,data)
}
//删除用户区域经济主题
export const delUserEcoTheme=(data)=>{
  return  $http.post(`/api/Business/DelUserEcoTheme`,data)
}
//获取主题的区域经济列表
export const getItemOfTheme=(themeId)=>{
  return  $http.get(`/api/Business/GetAreaEcoListOfTheme?themeId=${themeId}`)
}
//删除用户自定义的区域经济部件
export const delAreaEco=(data)=>{
  return  $http.post(`/api/Business/DelAreaEco`,data)
}
//设置区域经济部件的主题（复制或移动到其他主题）
export const setThemeOfAreaEco=(data)=>{
  return  $http.post(`/api/Business/SetThemeOfAreaEco`,data)
}
//添加用户自定义的区域经济部件
export const addAreaEco=(data)=>{
  return  $http.post(`/api/Business/AddAreaEco`,data)
}
//修改用户自定义的区域经济部件
export const changeAreaEco=(data)=>{
  return  $http.post(`/api/Business/ChangeAreaEco`,data)
}
//设置区域经济部件的次序
export const setAreaEcoSortOfTheme=(data)=>{
  return  $http.post(`/api/Business/SetAreaEcoSortOfTheme`,data)
}
//设置通用主题的次序
export const setThemeSort=(data)=>{
  return  $http.post(`/api/Business/SetThemeSort`,data)
}

/************************************ */
//用户自定义区域经济——个性
//添加用户区域经济主题
export const addUserEcoThemeSelf=(data)=>{
  return  $http.post(`/api/Business/AddUserEcoThemeSelf`,data)
}
//修改用户区域经济主题
export const changeUserEcoThemeSelf=(data)=>{
  return  $http.post(`/api/Business/ChangeUserEcoThemeSelf`,data)
}
//删除用户区域经济主题
export const delUserEcoThemeSelf=(data)=>{
  return  $http.post(`/api/Business/DelUserEcoThemeSelf`,data)
}
//获取主题的区域经济列表
export const getItemOfThemeSelf=(themeId)=>{
  return  $http.get(`/api/Business/GetAreaEcoListOfThemeSelf?themeId=${themeId}`)
}
//删除用户自定义的区域经济部件
export const delAreaEcoSelf=(data)=>{
  return  $http.post(`/api/Business/DelAreaEcoSelf`,data)
}
//设置区域经济部件的主题（复制或移动到其他主题）
export const setThemeOfAreaEcoSelf=(data)=>{
  return  $http.post(`/api/Business/SetThemeOfAreaEcoSelf`,data)
}
//添加用户自定义的区域经济部件
export const addAreaEcoSelf=(data)=>{
  return  $http.post(`/api/Business/AddAreaEcoSelf`,data)
}
//修改用户自定义的区域经济部件
export const changeAreaEcoSelf=(data)=>{
  return  $http.post(`/api/Business/ChangeAreaEcoSelf`,data)
}
//设置区域经济部件的次序
export const setAreaEcoSortOfThemeSelf=(data)=>{
  return  $http.post(`/api/Business/SetAreaEcoSortOfThemeSelf`,data)
}

/*****************我的关注******************* */

//获取用户收藏夹的数量
export const getNumOfUserFollow=(userId)=>{
  return  $http.get(`/api/Business/GetNumOfUserFollow?userid=${userId}`)
}

//添加关注的企业
export const addUserFollow=(data)=>{
  return  $http.post(`/api/Business/AddUserFollow`,data)
}

//删除关注的企业
export const delUserFollow=(data)=>{
  return  $http.post(`/api/Business/DelUserFollow`,data)
}
//获取关注的企业列表
export const getUserFollowList=(data)=>{
  return  $http.post(`/api/Business/GetUserFollowList`,data)
}
/*****************支付******************* */

//获取vip价格列表
export const getVIPPriceList=()=>{
  return  $http.get(`/api/Business/GetSaasVIPPriceList`)
}
//调用native支付接口返回支付链接url
export const createPayOrder=(objId)=>{
  return  $http.get(`/api/Payment/CreateNativePayOrder?objId=${objId}&type=1&app=20`)
}
//查询订单信息
export const getVipOrder=(data)=>{
  return  $http.post(`/api/Business/GetSaasVipOrder`,data)
}
