//import { inject } from '@vue/runtime-core';
import { createStore } from 'vuex'
import {getCurRegionCode} from '@/http/homeApi'
// import {getRealIP} from '@/utils/index'
//import {loginOut} from "../http/loginApi";  
//import { ElMessage } from 'element-plus'
export default createStore({
  state: {
    menuRouteLoaded:false,
    loginVisable:false, //登录对话框是否打开
    rightVisable:false,//权限不足对话框是否打开
    exampleUrl:'',//权限不足对话框中的样例图片url
    regionLevelList:[{id:0,name:'国家级'},{id:1,name:'省级'},{id:2,name:'市级'},{id:3,name:'区县级'}],
    curRegionCode:'',
   
  },
  mutations: {
    setMenuRouteLoadStatus: (state, status) => {
      state.menuRouteLoaded = status
    },
    setIsLoginVisable: (state, status) => {
      //console.log(state);
      //console.log(status);
      state.loginVisable = status
      //console.log(state.loginVisable);
    },
    setIsRightVisable: (state, status) => {
      //console.log(state);
      console.log(status);
      state.rightVisable = status[0],
      state.exampleUrl=status[1]
      //console.log(state.loginVisable);
    },
    setCurRegionCode:async(state,status)=>{
      //console.log('获取地区:',localStorage.getItem('ip'));
      if(status){
        state.curRegionCode=status;
        localStorage.setItem("curRegionCode",state.curRegionCode) 
      }else if(!localStorage.getItem("curRegionCode")){
        console.log('获取地区:',localStorage.getItem('ip'));
        let {code}=await getCurRegionCode(localStorage.getItem('ip'));
        state.curRegionCode=code;
        localStorage.setItem("curRegionCode",state.curRegionCode) 
      }
      else{
        state.curRegionCode=localStorage.getItem("curRegionCode");
      }
      
    },
  },
  actions: {
    // loginOut:(context,router)=>{
    //     console.log(context);
    //     //loginOut('xxx').then(response=>{// eslint-disable-line no-unused-vars
    //       localStorage.removeItem("accesstoken");
    //       localStorage.removeItem("currentUser");
    //       localStorage.removeItem("refreshToken"); 
    //       console.log(router);
    //       // if('1'.length>0){
    //       //   let reload=inject("reload");
    //       //   reload();
    //       // }
    //       router.push('/')
    //   //}).catch(error=>{
    //   //  console.log(error);
    //   //  alert("网络错误，请求失败");
    //   //})
    // }
  },
  modules: {
  }
})

// export default createStore({
//   state: {
//     sidebar: {
//       opened: localStorage.getItem('sidebarStatus') ? !!+localStorage.getItem('sidebarStatus') : true,
//       withoutAnimation: false
//     },
//     indicatorsCurDate:(new Date().getFullYear()-2)+'-01-01',//当前最新日期
//   },
//   mutations: {
//     TOGGLE_SIDEBAR: state => {
//       state.sidebar.opened = !state.sidebar.opened
//       state.sidebar.withoutAnimation = false
//       if (state.sidebar.opened) {
//         localStorage.setItem("sidebarStatus",1); 
//       } else {
//         localStorage.setItem("sidebarStatus",0); 
//       }
//     },
//     CLOSE_SIDEBAR: (state, withoutAnimation) => {
//       localStorage.setItem("sidebarStatus",0); 
//       state.sidebar.opened = false
//       state.sidebar.withoutAnimation = withoutAnimation
//     },
//   },
//   actions: {
//     toggleSideBar(content) {
//       content.commit('TOGGLE_SIDEBAR')
//     },
//     closeSideBar(content, { withoutAnimation }) {
//       content.commit('CLOSE_SIDEBAR', withoutAnimation)
//     },
//   },
//   modules: {
//   }
// })
