<template>
    <div @click="()=>{isShowQueryRes=false;fnCallChild();}">
        <HeaderFirst :class="{'index_hide_header':isShowHeader}" ref='refHeaderFirst'></HeaderFirst>
        <div class="index_top">
            <div class="index_header container clearfix" :class="{'index_hide_header':!isShowHeader}">
                <div class="index_home-header-left__dNzdP">
                    <div class="index_home-header-logo__1fJ3P">
                        <img src="https://data.chinaecono.com/images/logo_w_1.png?t=1683356233" alt="">
                        <span>经济运行平台</span>
                    </div>
                </div>
                <div class="index_home-header-right__DYnQ4">
                    <div class="undefined undefined treasure_nav-item__TjRqC">
                        <!-- <el-button v-if="!currentUser" type="primary" @click='loginVisable=true;'>登录/注册</el-button> -->
                        <el-link v-if=" !currentUser || currentUser?.expires<Math.round(new Date().getTime()/1000)" :underline="false" style="color:#fff;"  @click.prevent='loginVisable=true;'>登录/注册</el-link>
                        <el-popover v-if="currentUser && currentUser?.expires>=Math.round(new Date().getTime()/1000)" placement="bottom" :width="200" trigger="hover">
                        <template #reference>
                            <el-avatar   :size="30" :src="currentUser?.picUrl" style="cursor: pointer;" ></el-avatar>
                        </template>
                        <div class="user-float" >
                            <div class="user-name">
                                <span style="">{{currentUser?.userName}}</span>
                            </div>
                            <ul>
                                <li class="css-1e76yen" @click="push(`/people`,'people')">
                                    <i class="iconfont ec-icon-s-user"></i>
                                    <span>我的主页</span>
                                </li>
                                <li class="css-1e76yen" @click="push('/user-center','usercenter')">
                                    <i class="iconfont ec-icon-setting-fill"></i>
                                    <span>个人中心</span>
                                </li>
                                <li class="css-1e76yen" @click="loginOut">
                                    <i class="iconfont ec-icon-exit"></i>
                                    <span>退出</span>
                                </li>
                            </ul>
                        </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            
            <div class="container text-center clearfix">
                <div class="index_home_title">在线经济大数据智能分析平台</div>
                <div class="index_tab_main">
                    <ul class="index_home_suggest_tab">
                        <li v-for="item in queryClassList" :key="item.id" class="index_home_suggest_tab_item" :class="{'index_home_suggest_tab_active':item.isCheck}" @click='changeQueryClass(item)'>{{item.name}} </li>
                    </ul>
                    <div class="index_home_suggest_content" @click.stop >
                       
                        <div class="index_home_suggest_input flex_e3f88">
                            <el-input v-model="search" :placeholder="queryClassList.find(t=>t.isCheck).desc" @input="inputFun"  @focus="inputFun"  />
                        </div>
                       
                        <el-tooltip :visible="isTooltipVisible"  effect="light"  placement="bottom-start" trigger='click' :offset="tooltipOffset" :auto-close='2000'>
                            <template #content>
                                <span>{{tooltipInfo}}</span>
                            </template>
                            <button class="flex_50ab4 index_home_suggest_button" @click="btnFun">
                                <span>查一下</span>
                            </button>
                        </el-tooltip>
                        <div class="index_popover_box" v-if='isShowQueryRes' >
                            <ul>
                                <li v-for="item in queryResList" :key="item.code">
                                    <a v-if='queryClassList.find(t=>t.isCheck).id==1'    @click.stop.prevent="isShowQueryRes=false;pushSearch(item)" >
                                        <span class="firstLine">{{item.name}}</span>
                                        <span class="secondLine">{{item.parent}}</span>
                                    </a>
                                    <a v-else  class="popover_box_item"  @click.stop.prevent="isShowQueryRes=false;pushSearch(item)">
                                         <span >{{item.name}}</span>
                                        <span class="popover_box_item_primary">{{item.parent}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div  class="index_hot_container">
                        <div class="index_hotlist">
                            <div class="index_hotlist_title">
                                <div>热门</div>
                                <div>搜索</div>
                            </div>
                            <div class="index_hotlist_keys">
                                <a  v-for="item in hotSearchList" :key="item" class="index_hotlist_key"  @click.prevent="pushSearch(item)">{{item.name}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container clearfix">
            <div class="index_home-area-item__zvjt_ index_-col-5__9oSRq" >
                <div class="index_img-warp__ZiUki" style="background: repeating-linear-gradient(to left,rgba(130,190,71,0.4),rgba(130,190,71,0.2),rgba(130,190,71,0.1));">
                     <!-- <svg class="icon para-title-i" aria-hidden="true">
                        <use xlink:href="#ec-icon-hongguan"></use>
                    </svg> -->
                    <i class="iconfont ec-icon-hongguan" style="color:rgba(130, 190, 71, 1);"></i>
                </div>
                <div class="index_home-area-inner__YcfPx">
                    <div class="index_home-area-title__U1il7">宏观经济</div>
                    <div class="index_home-area-intro__cTVyP">600W+</div>
                </div>
            </div>
            <div class="index_home-area-item__zvjt_ index_-col-5__9oSRq" >
                <div class="index_img-warp__ZiUki" style="background: repeating-linear-gradient(to left,rgba(54,126,232,0.4),rgba(54,126,232,0.2),rgba(54,126,232,0.1));">
                    <!-- <svg class="icon para-title-i" aria-hidden="true">
                        <use xlink:href="#ec-icon-hangye"></use>
                    </svg> -->
                    <i class="iconfont ec-icon-hangye" style="color:rgba(54,126,232,1);"></i>
                </div>
                <div class="index_home-area-inner__YcfPx">
                    <div class="index_home-area-title__U1il7">行业经济</div>
                    <div class="index_home-area-intro__cTVyP">300w+</div>
                </div>
            </div>
            <div class="index_home-area-item__zvjt_ index_-col-5__9oSRq" >
                <div class="index_img-warp__ZiUki" style="background: repeating-linear-gradient(to left,rgba(153,103,252,0.4),rgba(153,103,252,0.2),rgba(153,103,252,0.1));">
                    <i class="iconfont ec-icon-weiguan" style="color:rgb(153,103,252)"></i>
                </div>
                <div class="index_home-area-inner__YcfPx">
                    <div class="index_home-area-title__U1il7">微观经济</div>
                    <div class="index_home-area-intro__cTVyP">3000w+</div>
                </div>
            </div>
                <div class="index_home-area-item__zvjt_ index_-col-5__9oSRq" >
                    <div class="index_img-warp__ZiUki" style="background: repeating-linear-gradient(to left,rgba(27,209,199,0.4),rgba(27,209,199,0.2),rgba(27,209,199,0.1));">
                    <i class="iconfont ec-icon-ep" style="color:rgb(27,209,199)"></i>
                </div>
                    <div class="index_home-area-inner__YcfPx">
                        <div class="index_home-area-title__U1il7">企业数量</div>
                        <div class="index_home-area-intro__cTVyP">2.3亿+</div>
                    </div>
            </div>
            <div class="index_home-area-item__zvjt_ index_-col-5__9oSRq" >
                <div class="index_img-warp__ZiUki" style="background: repeating-linear-gradient(to left,rgba(255,149,5,0.4),rgba(255,149,5,0.2),rgba(255,149,5,0.1));">
                    <i class="iconfont ec-icon-xinwen" style="color:rgb(255,149,5)"></i>
                </div>
                <div class="index_home-area-inner__YcfPx">
                    <div class="index_home-area-title__U1il7">经济舆情<i class="tic tic-area-triangle index_home-area-triangle__3jAmI"></i></div>
                    <div class="index_home-area-intro__cTVyP">20w+</div>
                </div>
            </div>
        </div>
        <div class="index_box_news">
            <div class="index_search_tab container clearfix">
                <div class="index_content ">
                    <div class="index_left">
                        <div v-for="item in quickQueryClassList" :key="item.id" class="index_list_item" :class="{'index_active':item.isCheck}" @click='changeQuickQueryClass(item)'><span>{{item.name}}</span></div>
                    </div>
                    <el-scrollbar class="index_right" v-if="quickQueryClassList.filter(t=>t.isCheck && t.id==1).length>0">
                        <div  >
                            <div class="index_row__OfLIy">
                                <div class="index_item___BGg3 index_-right__1_Hlv index_-prov__mGT8R">
                                    <a  class="link-hover-click overflow-width"  @click.prevent="pushSearch({code:'110000'})">北京</a>
                                </div>
                                <div class="index_item___BGg3 index_-right__1_Hlv index_-prov__mGT8R">
                                    <a @click.prevent="pushSearch({code:'120000'})" class="link-hover-click overflow-width" >天津</a>
                                </div>
                                <div class="index_item___BGg3 index_-right__1_Hlv index_-prov__mGT8R">
                                    <a @click.prevent="pushSearch({code:'310000'})" class="link-hover-click overflow-width" >上海</a>
                                </div>
                                <div class="index_item___BGg3 index_-right__1_Hlv index_-prov__mGT8R">
                                    <a @click.prevent="pushSearch({code:'500000'})"  class="link-hover-click overflow-width" >重庆</a>
                                </div>
                            </div>
                            <div class="index_row__OfLIy" v-for="region in regionList?.filter(t=>['110000','120000','310000','500000'].indexOf(t.code)<0)" :key='region.code'>
                                <div class="index_item___BGg3 index_col-1__KVloU index_-prov__mGT8R">
                                    <a @click.prevent="pushSearch({code:region.code})"  class="link-hover-click overflow-width" target="_blank">{{region.name}}</a>
                                </div>
                                <div class="index_col-11__5hafL">
                                    <a v-for="subRegion in region.children" :key="subRegion.code" @click.prevent="pushSearch({code:subRegion.code})"  class="link-sub-hover-click index_item___BGg3 index_-right__1_Hlv" target="_blank">{{subRegion.name}}</a>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar>
                    <el-scrollbar class="index_right" v-if="quickQueryClassList.filter(t=>t.isCheck && t.id==2).length>0">
                        <div  >
                            <div class="index_row__OfLIy" v-for="item in itemList" :key='item.itemId'>
                                <div class="index_item___BGg3 index_col-1__KVloU index_-prov__mGT8R">
                                    <span class="link-hover-click overflow-width" >{{item.itemName}}</span>
                                </div>
                                <div class="index_col-11__5hafL">
                                    <a v-for="subItem in item.children" :key="subItem.itemId" :href="`/#/${item.path}?key=${subItem.itemName}`"  class="link-sub-hover-click index_item___BGg3 index_-right__1_Hlv" target="_blank">{{subItem.itemName}}</a>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="loginVisable"
            title=""
            :width='420'
            custom-class="login-dialog"
            destroy-on-close
            :close-on-click-modal='false'
            >
            <login></login>
        </el-dialog>
        <div class="floatbtnPublic">
            <div >
                <el-popover placement="left" :width="200" trigger="hover">
                    <template #reference>
                        <div class="listbtnPublic">
                            <i class="iconfont ec-icon-service"></i>
                            <span>联系我们</span>
                        </div>
                        
                    </template>
                    <div style="text-align:center;">
                        <img src="https://data.chinaecono.com/images/wx_code.jpg" style="width:100%;" alt="企业微信">
                        <span>扫码加企业微信</span>
                    </div>
                </el-popover>
            </div>
            <div >
                <el-popover placement="left" :width="200" trigger="hover">
                    <template #reference>
                        <div class="listbtnPublic">
                            <i class="iconfont ec-icon-wechat-fill"></i>
                            <span>公众号</span>
                        </div>
                    </template>
                    <div style="text-align:center;">
                        <img src="https://data.chinaecono.com/images/qr_code.jpg"  style="width:100%;" alt="经济运行微信公众号">
                        <span>公众号：趋势观察</span>
                    </div>
                </el-popover>
            </div>
        </div>
        <FooterNew></FooterNew>
    </div>
    
</template>
<script>
import {computed, inject, onBeforeUnmount, onMounted, ref } from '@vue/runtime-core';
import {getRandomItemList,getItemList} from '@/http/homeApi'
import {addUserLog,getUserRightFun} from '@/http/basicsApi'
import {useRouter } from 'vue-router';
import FooterNew from '@/components/FooterNew.vue'
import Login from '@/components/Login.vue'
import HeaderFirst from '@/components/HeaderFirst.vue'
import {qcList} from '@/utils/index.js'
export default {
    components:{
        FooterNew,
        Login,
        HeaderFirst
    },
    setup() {
        let router_=useRouter();
        let reload=inject('reload');
        let regionList=inject('regionList')
        let loginVisable=ref(false);
        let currentUser=computed(()=>JSON.parse( localStorage.getItem('currentUser')));//当前用户信息
        //console.log(currentUser.value);
        let isShowHeader=ref(true);//是否显示
        let queryClassList=ref(qcList);
        let quickQueryClassList=ref([{id:1,name:"按地区查询",isCheck:true},{id:2,name:"按指标查询",isCheck:false}]);
        let search=ref('');//搜索框信息
        let simpleSearch=ref('');//
        let hotSearchList=ref([])
        let itemList=ref([]);
        let refHeaderFirst=ref();
        // 滚动加载
        const lazyLoading= ()=> {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;// 滚动条滚动时，距离顶部的距离
            //const windowHeight = document.documentElement.clientHeight;// 可视区的高度
            //const scrollHeight = document.documentElement.scrollHeight;// 滚动条的总高度
            //console.log(scrollTop);
            if(scrollTop>300){
                isShowHeader.value=false;
                fnCallChild();
            }
            else{
                isShowHeader.value=true;
            }
        }
        //改变查询的分类
        const changeQueryClass=(item)=>{
            queryClassList.value.forEach(t=>{
                if(t.id==item.id){
                    t.isCheck=true
                }
                else if(t.isCheck){
                    t.isCheck=false
                }
                search.value='';
                queryResList.value=[];
                isShowQueryRes.value=false;
                if(item.id==1){
                    hotSearchList.value=[{code:'110000',name:'北京市'},{code:'120000',name:'天津市'},{code:'310000',name:'上海市'},{code:'500000',name:'重庆市'},{code:'130700',name:'河北省-张家口市'},
                            {code:'440300',name:'广东省-深圳市'},{code:'440100',name:'广东省-广州市'},{code:'420100',name:'湖北省-武汉市'},{code:'320100',name:'江苏省-南京市'},{code:'410100',name:'河南省-郑州市'}];
                }
                else if(item.id==2){
                    hotSearchList.value=[{code:'1',name:'地区生产总值'},{code:'2',name:'资产'},{code:'3',name:'人口'},{code:'4',name:'进出口'},{code:'5',name:'固定资产投资'}
                    ,{code:'6',name:'房地产'},{code:'7',name:'利润率'},{code:'8',name:'学校数'},{code:'9',name:'住宅房价'},{code:'10',name:'住宅租金'}
                    ,{code:'11',name:'房地产'},{code:'12',name:'利润率'},{code:'13',name:'学校数'},{code:'14',name:'住宅房价'},{code:'15',name:'住宅租金'}];
                }
            });
        }
        //输入框输入内容
        let queryResList=ref([]);
        let isShowQueryRes=ref(false)//是否显示搜索结果
        const inputFun=async()=>{
            queryResList.value=[];
            isShowQueryRes.value=true;
            if(search.value){
                //判断是地区查、指标查
                let selType=queryClassList.value.find(t=>t.isCheck);
                if(selType.id==1){//地区查
                    let subRegion=[];
                    regionList.value.forEach(t=>{
                        if(t.name.indexOf(search.value)>-1){
                            queryResList.value.push({code:t.code,name:t.name,parent:''});
                            subRegion=[...subRegion,...t.children.map(m=>{return { ...m,parent:t.name}})];
                        }
                        let filterAry= t.children.filter(m=>m.name.indexOf(search.value)>-1 );
                        if(filterAry.length>0){
                            let pushAry=filterAry.map(m=>{return { ...m,parent:t.name}});
                            queryResList.value=[...queryResList.value,...pushAry];
                        }
                    })
                    let aryLength=queryResList.value.length;
                    if(aryLength<8 && subRegion.length>0){

                        let pushAry=subRegion.filter((t,index)=>index<8-aryLength-1);
                        //console.log(pushAry);
                        queryResList.value=[...queryResList.value,...pushAry];
                    }
                    else if(aryLength>8){
                        queryResList.value=queryResList.value.filter((t,index)=>index>8-1);
                    }
                }
                else if(selType.id==2){//指标查
                    getItemListFun();
                }
            }
        }
        //搜索查询指标
        const getItemListFun= async()=>{
            let params={
                search:search.value,
                pageIndex:1,
                pageSize:10
            }
            let res=await getItemList(params);
            if(res.code==20000){
                res.data.forEach(t=>{
                    if(t.parentId==20){
                        t.path='search'
                    }
                    else if(t.parentId==30){
                        t.path='search/ecoIndustrySearch';
                    }
                    if(t.parentId==50){
                        t.path='search/microEcoSearch'
                    }
                    else if(t.parentId==70){
                        t.path='search/ecoArticleSearch';
                    }
                });
                queryResList.value=res.data;
            }
        }
        //单击‘查一下’按钮
        let isTooltipVisible=ref(false);//按钮确认提示
        let tooltipInfo=ref('');//提示内容
        let tooltipOffset=ref(0);//提示的偏移量
        const btnFun=()=>{
            isTooltipVisible.value=false;
            tooltipInfo.value='';
            if(!search.value){
                isTooltipVisible.value=true;
                tooltipInfo.value="请输入搜索的关键词";
                tooltipOffset.value=10;
                return;
            }
            //判断是地区查、指标查
            let selType=queryClassList.value.find(t=>t.isCheck);
            if(selType.id==1){//地区
                let filterAry=queryResList.value.filter(t=>t.name.indexOf(search.value)>-1);
                if(filterAry.length==1){//能够唯一确定地区就直接跳转到地区经济的首页
                    pushSearch(filterAry[0]);
                }
                else{//如果不能确定就提示
                    tooltipOffset.value=10;
                    isTooltipVisible.value=true;
                    tooltipInfo.value="请确定要搜索的地区名称";
                } 
            }
            else if(selType.id==2){
                pushSearch({name:search.value});
            }
            //记录用户操作日志
            addUserLog(router_.currentRoute.value.name,router_.currentRoute.value.fullPath,'查询',JSON.stringify({type:selType.id==1?'地区':'指标', search:search.value}));
        }
        const pushSearch=(item)=>{
            let selType=queryClassList.value.find(t=>t.isCheck);
            //console.log(selType);
            if(selType.id==1){
                localStorage.setItem('curRegionCode',item.code)
                const { href } = router_.resolve({
                    path: '/home',
                });
                //console.log(href)
                window.open(href, "_blank");
            }
            else if(selType.id==2){
                const { href } = router_.resolve({
                    path: '/search',
                    query: {
                        key: item.name
                    }
                });
                window.open(href, "_blank");
            }
        }
        //改变快速查询的分类
        const changeQuickQueryClass=(item)=>{
            quickQueryClassList.value.forEach(t=>{
                if(t.id==item.id){
                    t.isCheck=true
                }
                else if(t.isCheck){
                    t.isCheck=false
                }
            });
            //console.log(quickQueryClassList.value);
        }
        //随机获取指标列表
        const getRandomItemListFun=async()=>{
            let res= await getRandomItemList();
            //console.log(res);
            if(res.code==20000){
                itemList.value=res.data;
                itemList.value.forEach(t=>{
                    if(t.itemId==20){
                        t.path='search'
                    }
                    else if(t.itemId==30){
                        t.path='search/ecoIndustrySearch';
                    }
                    if(t.itemId==50){
                        t.path='search/microEcoSearch'
                    }
                    else if(t.itemId==70){
                        t.path='search/ecoArticleSearch';
                    }
                })
            }
        }
        //跳转
        const push=async (path,name)=>{
            console.log(path);
            if(await getUserRightFun(name,0)){
                router_.push(path)
            }
        }
         //退出
        const loginOut=()=>{
            //loginOut('xxx').then(response=>{// eslint-disable-line no-unused-vars
                localStorage.removeItem("accesstoken");
                localStorage.removeItem("currentUser");
                localStorage.removeItem("refreshToken"); 
                reload();
        }
        const fnCallChild = () => {
            refHeaderFirst.value.hideQueryResFun();      //hideQueryResFun子组件里的方法
        };
        //执行加载
        changeQueryClass({id:queryClassList.value.find(t=>t.isCheck).id});
        getRandomItemListFun();
        //记录用户操作日志
        addUserLog(router_.currentRoute.value.name,router_.currentRoute.value.fullPath,'加载','');
        window.addEventListener('scroll',lazyLoading);//监听
        onMounted(()=>{
        
        })
        onBeforeUnmount(()=>{//销毁之前
            window.removeEventListener('scroll',lazyLoading)
        })
        return{
            currentUser,
            isShowHeader,
            loginVisable,
            queryClassList,
            changeQueryClass,
            quickQueryClassList,//快速查询分类
            changeQuickQueryClass,
            search,
            simpleSearch,
            inputFun,
            btnFun,
            isTooltipVisible,
            tooltipInfo,
            tooltipOffset,
            queryResList,
            isShowQueryRes,
            hotSearchList,
            pushSearch,
            regionList,//地区列表
            itemList,
            push,
            loginOut,
            refHeaderFirst,
            fnCallChild
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
 .index_header {
    position: relative;
    padding: 0;
    height: 70px;
    zoom: 1;
}
.index_home-header-left__dNzdP {
    float: left;
    position: relative;
}
.index_home-header-logo__1fJ3P {
    // position: absolute;
    // display: inline-flex;
    // vertical-align: middle;
    // cursor: pointer;
    // height: 55px;
    // margin-top: 7px;
    img {
        height: 35px;
        margin-top: 17px;
        vertical-align: bottom;
    }
    span{
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #fff;
        color: #fff;
        font-size: 22px;
        line-height: 35px;
    }
}
.index_home-header-right__DYnQ4 {
    position: absolute;
    right: 0;
    top: 0;
    height: 70px;
}
.index_home-header-right__DYnQ4 .index_tyc-nav__vtu_I {
    padding-top: 21px;
    line-height: 14px;
    font-size: 14px;
    
    position: relative;
}
.index_hide_header {
    display: none;
    transition: all .5s;
}


.index_top {
    width: 100%;
    height: 445px;
    position: relative;
    // background-image: url(https://data.chinaecono.com/images/home_head_bg.jpg);
    background-position: 50%;
    background-size: cover;
    background: radial-gradient(circle,rgba($themeColor,0.8),$themeColor);
}
.treasure_nav-item__TjRqC:last-child {
    padding-right: 0;
}
.treasure_nav-item__TjRqC {
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    padding: 28px 10px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    vertical-align: top;
    cursor: pointer;
    color: #fff;
}
.treasure_nav-link__7ErdH {
    cursor: pointer;
}

.index_home_title {
    display: inline-block;
    margin-bottom: 33px;
    margin-top: 40px;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 8px;
    text-indent: 7px;
    color: #fff;
}
.index_tab_main {
    text-align: center;
    color: #fff;
    width: 880px;
    margin: 0 auto;
}
.index_home_suggest_tab {
    font-size: 0;
    color: #fff;
    margin-left: 37px;
}
.index_home_suggest_tab .index_home_suggest_tab_item {
    display: inline-block;
    width: 64px;
    padding: 3px 6px;
    line-height: 1.42857143;
    margin-right: 32px;
    margin-bottom: 14px;
    border-radius: 2px;
    font-size: 16px;
    background-color: transparent;
    cursor: pointer;
}
.index_home_suggest_tab .index_home_suggest_tab_item.index_home_suggest_tab_active {
    color: #0084ff;
    background-color: #fff;
    position: relative;
}
.index_home_suggest_tab .index_home_suggest_tab_item.index_home_suggest_tab_active:after {
    position: absolute;
    left: 35%;
    top: 95%;
    content: "";
    width: 0;
    height: 0;
    margin-top: 1px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
}
.index_home_suggest_content {
    position: relative;
    z-index: 3;
    display: flex;
    width: 880px;
    height: 48px;
    background-color: #fff;
    border-radius: 4px;
    text-align: left;
    font-size: 16px;
    color: #333;
}
.index_home_suggest_content__Q0jE5 .index_home_suggest_input {
    padding: 5px 15px;
    border: none;
    vertical-align: middle;
    line-height: 24px;
    font-size: 16px;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
}
.flex_e3f88 {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 0 12px;
  position: relative;
  transition: all .3s;
  width: 100%;
}
.index_home_suggest_input  {
    ::v-deep .el-input__inner{
        border: none;
    }
    
    // display: inline-block;
    // font-size: inherit;
    // height: 100%;
    // line-height: inherit;
    // outline: none;
    // border-radius: 2px;
    // padding: 0 12px;
    // transition: all .3s;
    // width: 100%;
}
.index_home_suggest_content .index_home_suggest_button {
    width: 112px;
    height: 100%;
    padding: 14px 10px;
    border-radius: 0 4px 4px 0;
    border: 1px solid #fe7d18;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    background: #fe7d18;
    box-sizing: border-box;
    &:focus{
        outline:none;
    }
}
.flex_50ab4 {
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    line-height: 36px;
    padding: 0 16px;
    transition: all .3s;
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.index_popover_box{
    overflow: visible;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
    overflow: hidden;
    top: 48px;
    width: 779px;
    position: absolute;
}
.index_popover_box li {
    align-items: center;
    border-top: 1px solid #f3f3f3;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    &:hover {
        background-color: rgba(0,132,255,.08);
    }
    a{
        width:100%;
    }
    a.popover_box_item{
        line-height: 40px;
        .popover_box_item_primary {
            background-color: rgba(68,157,230,.1);
            color: #449de6;
            line-height: 1.5;
            float: right;
            margin-top: 8px;
            padding:0 5px;
            border-radius: 4px;
        }
    }
}
.index_popover_box .firstLine {
    font-size: 16px;
    line-height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    display: block;
}
.index_popover_box .secondLine {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 22px;
    display: block;
}
.index_hot_container {
    width: 880px;
    margin: 0 auto;
}
.index_hotlist {
    width: 100%;
    height: 56px;
    margin-top: 32px;
    text-align: left;
    font-size: 0;
    color: hsla(0,0%,100%,.82);
    overflow: hidden;
}
.index_hotlist .index_hotlist_title {
    display: inline-block;
    width: 56px;
    height: 56px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 32px;
    padding-top: 5px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 4px;
    text-indent: 4px;
    line-height: 22px;
    background: hsla(0,0%,100%,.16);
}
.index_hotlist .index_hotlist_keys {
    display: inline-block;
    width: 786px;
    height: 56px;
    vertical-align: top;
    text-align: left;
}
.index_hotlist .index_hotlist_key {
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #e0e0e0;
}
.index_hotlist .index_hotlist_key:hover {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}
.index_home-area-item__zvjt_.index_-col-5__9oSRq {
    width: 240px;
    padding: 20px 10px;
}
.index_home-area-item__zvjt_+.index_home-area-item__zvjt_ {
    margin-left: 12px;
}
.index_home-area-item__zvjt_ {
    position: relative;
    display: block;
    float: left;
    width: 303px;
    height: 104px;
    padding: 20px 24px;
    margin-top: 24px;
    color: #333;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 4%);
    background: #fff;
    // cursor: pointer;
    transition: box-shadow .3s;
}
.index_home-area-item__zvjt_.index_-col-5__9oSRq .index_img-warp__ZiUki {
    left: 10px;
}

.index_home-area-item__zvjt_ .index_img-warp__ZiUki {
    position: absolute;
    left: 24px;
    height: 64px;
    width: 64px;
    text-align: center;
    padding-top: 16px;
    border-radius: 6px;
    svg,i{
        font-size: 32px;
    }
}
.index_home-area-item__zvjt_ .index_img__IGmd9 {
    width: 64px;
    height: 64px;
}


.index_home-area-item__zvjt_.index_-col-5__9oSRq .index_home-area-inner__YcfPx {
    padding-left: 74px;
}
.index_home-area-item__zvjt_ .index_home-area-title__U1il7 {
    padding-top: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #333;
}
.index_home-area-item__zvjt_ .index_home-area-intro__cTVyP {
    padding-top: 4px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #0084ff;
}
.index_box_news {
    margin-top: 24px;
    background-color: #fff;
}
.index_search_tab {
    margin-top: 24px;
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #fff;
}
.index_search_tab .index_content {
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    background-color: #fff;
}
.index_search_tab .index_left {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    height: 406px;
    padding: 16px 0 34px;
    border-right: 1px solid #f3f3f3;
}
.index_search_tab .index_left .index_list_item.index_active {
    font-weight: 700;
    color: #fff;
    background: $themeColor;
}
.index_search_tab .index_left .index_list_item {
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding-left: 32px;
    color: #202020;
    cursor: pointer;
    transition: background 1s cubic-bezier(.075,.82,.165,1);
}
.index_search_tab .index_right {
    display: inline-block;
    width: 1008px;
    height: 406px;
    overflow: auto;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 21px;
}
.index_row__OfLIy.index_-single__ZOmpQ, .index_row__OfLIy:first-child {
    padding-top: 0;
}

.index_row__OfLIy {
    *zoom: 1;
    padding: 17px 0 8px;
    border-bottom: 1px solid #f3f3f3;
}
.index_row__OfLIy:after, .index_row__OfLIy:before {
    content: "";
    display: table;
}
.index_row__OfLIy:after {
    clear: both;
    overflow: hidden;
}
.index_item___BGg3.index_-prov__mGT8R, .index_item___BGg3.index_-strong___XzrR {
    font-weight: 700;
}
.index_item___BGg3:hover {
    color: $themeColor;
}
.index_item___BGg3 {
    display: inline-block;
    line-height: 21px;
    margin-bottom: 7px;
}
.index_col-1__KVloU {
    width: 8.33333333%;
}
.index_col-10__7fJio, .index_col-11__5hafL, .index_col-12__Lc8of, .index_col-1__KVloU, .index_col-2__eCpig, .index_col-3__KJ_gX, .index_col-4__64_z6, .index_col-5__fHURd, .index_col-6__RDWiJ, .index_col-7__gmknc, .index_col-8__otBiU, .index_col-9__D22QY {
    float: left;
}
.index_col-11__5hafL {
    width: 91.66666667%;
}

.index_col-10__7fJio, .index_col-11__5hafL, .index_col-12__Lc8of, .index_col-1__KVloU, .index_col-2__eCpig, .index_col-3__KJ_gX, .index_col-4__64_z6, .index_col-5__fHURd, .index_col-6__RDWiJ, .index_col-7__gmknc, .index_col-8__otBiU, .index_col-9__D22QY {
    float: left;
}
.index_item___BGg3.index_-right__1_Hlv {
    padding-right: 24px;
}

.index_item___BGg3 {
    display: inline-block;
    line-height: 21px;
    margin-bottom: 7px;
}
.floatbtnPublic {
    position: fixed;
    right: 10px;
    top: 600px;
    margin-top: -100px;
    z-index: 999999;
    // top: unset !important;
    // bottom: 50% !important;
}
.listbtnPublic {
    margin-bottom: 6px !important;
    width: 65px;
    min-height: 1px;
    overflow: hidden;
    border-radius: 4px;
    background-color:  rgba(0, 47, 167, 0.8);
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    padding: 10px 2px;
    height: auto;
    i{
        font-size: 24px;
    }
    span {
        line-height: 20px;
        display: block;
    }
}
</style>