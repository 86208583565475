import router  from '@/router'
//import {treeFind} from './index'
import {getUserRightFun} from '@/http/basicsApi.js'
export const hasPermission = {
    install(Vue){
        Vue.directive('has', {
            async mounted(el, binding) {
                // 获取存放在localStorage的权限   
                // const menuInfo =treeFind(JSON.parse(localStorage.getItem("navMenuData")),function(item){
                //     if(item.name.toLowerCase()==binding.value.page.toLowerCase()){
				// 		return true
				// 	}
				// 	else {
				// 		return false
				// 	}
                // })
                let btnList=router.currentRoute.value.meta.permission;
                let menuId=router.currentRoute.value.meta.menuId;
                if ( !btnList || btnList.length<1 || !btnList.includes(binding.value.action)) { //操作按钮的显示隐藏，禁用按钮隐藏
                    el.parentNode.removeChild(el)
                }
                else {//用户的按钮权限的判断
                    //获取用户的按钮权限
                    let res= await getUserRightFun(binding.value.action,1,menuId,1);
                    if(!res && binding.value.rightType==0){//无权限，操作按钮隐藏，rightType=0 表示实际操作的按钮，rightType=1 表示屏蔽操作按钮的按钮
                        el.parentNode.removeChild(el)
                    }
                    else if(res && binding.value.rightType==1){//有权限，屏蔽按钮隐藏
                        el.parentNode.removeChild(el)
                    }
                }
            }
        })
    }
}
export default hasPermission;