<template>
<div class="verify-container" :style="{width: `${width}px`}">
	<div class="refresh" @click="reset">
		<svg t="1637315258145" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2420" width="20" height="20"><path d="M960 416V192l-73.056 73.056a447.712 447.712 0 0 0-373.6-201.088C265.92 63.968 65.312 264.544 65.312 512S265.92 960.032 513.344 960.032a448.064 448.064 0 0 0 415.232-279.488 38.368 38.368 0 1 0-71.136-28.896 371.36 371.36 0 0 1-344.096 231.584C308.32 883.232 142.112 717.024 142.112 512S308.32 140.768 513.344 140.768c132.448 0 251.936 70.08 318.016 179.84L736 416h224z" p-id="2421" fill="#8a8a8a"></path></svg>
	</div>
	<div class="pic">
		<canvas class="canvas_img" ref="canvasimg" :width="width" :height="height"></canvas>
		<canvas class="canvas_block" ref="canvas_block" :width="width" :height="height" :style="{left: blockLeft+'px'}"></canvas>
	</div>
	<div class="slider" :style="{height: blockW+'px'}">
		<div class="tip" v-if="showText">向右滑动完成验证</div>
		<div :class="['bar', slideState]" :style="{width: sliderLeft + 'px'}"></div>
		<div ref="slidericon" :class="['slider-icon', slideState]"
			:style="{left: sliderLeft + 'px'}" 
			@mousedown="mousedown"
			@mousemove="mousemove"
			@mouseup="mouseup">
			<i class="iconfont" :class="{ active: 'ec-icon-right', fail: 'ec-icon-close', success: 'ec-icon-check' }[slideState] || 'ec-icon-right'"></i> 
		</div>
	</div>
</div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { nextTick, onBeforeUnmount, onMounted } from '@vue/runtime-core'
export default {
  props: {
    width: {
      type: Number,
      default: 320
    },
    height: {
      type: Number,
      default: 160
    },
    blockW: { // 裁剪canvas宽高
      type: Number,
      default: 40
    },
    accuracy: {  // 精度
      type: Number,
      default: 1
    },
    
  },
emits: ['loginfun'],
  setup(props,{ emit }) {
    let bgImg= null  // 背景图
		let ctxImg= null // 背景画笔
		let ctxBlock= null // 滑块画笔
    let blockRect= {  // 滑块宽、圆半径、坐标
      w: props.blockW + 2 * props.blockW / 4,
      r: props.blockW / 4,
      x: 0,
      y: 0
    }
    let blockLeft=ref(0) // 裁剪后left属性
    let startX= 0 // 滑动起点
    let endX= 0 // 结束位置
    let sliderLeft=ref(0)  // 拖动滑块的滑动距离
    let slideState= ref('')  // success fail active
    let timeIns= null
    let showText=ref(true) // 是否显示滑动提示
    let isMouseDown= false
    let canvasimg=ref(null);
    let canvas_block=ref(null);
    let images=  ['4f63496d4886f5574f8eab0b16f7db8b.jpeg',
				'cc0685eb05bf7f469c5770adaf378246.jpeg',
				'cbbf0caa623e80e13c62ceacad8fd06a.jpeg',
				'a2218c340004a41bcbff946dd69b00f6.jpeg',
				'5555eef1ee35ffa6726b418e262e7507.jpeg',
				'f5406969e93f9aa8521625a94a4bac45.jpeg',
				'960b8d11eb2101719d3e8e49a3be3a7d.jpeg',
				'842fa24d3e7a80aace09532fd29706ad.jpeg',
				'0467e28fd209e56e511ef872b6d979bb.jpeg',
				'5cf61759b0451b1d8c6b4107d2dcc144.jpeg',
				'1247752182auto138&f.jpg',
				'1419668894auto138&f.jpg',
				'1474933429auto138&f.jpg',
				'1479313217auto138&f.jpg',
				'1704688164auto138&f.jpg',
				'1796558744auto120&f.jpg',
				'1965408672auto138&f.jpg',
				'2444228309auto120&f.jpg',
				'2653730783auto138&f.jpg',
				'2837678514auto138&f.jpg',
				'3381210348auto138&f.jpg',
				'191252156253&fmt8&f.jpg',
				]
    const init =()=> {// eslint-disable-line no-unused-vars
      nextTick(()=>{
        //console.log( canvasimg.value);
        //console.log( canvas_block.value);
        ctxImg = canvasimg.value.getContext('2d');
        ctxBlock = canvas_block.value.getContext('2d');

        getImg()
      })
    };
    const getImg= ()=> {
      const img = document.createElement('img');
      const imagesLen = images.length;
      const randomIndex = Math.floor(Math.random() * imagesLen);
      img.crossOrigin = "Anonymous"; 
      img.src = 'https://data.chinaecono.com/images/loginVerify/'+ images[randomIndex];
      bgImg = img;

      img.onload = () => {
        //console.log('图片加载完成')
        ctxImg.drawImage(bgImg, 0, 0, props.width, props.height);
        getBlockPostion()
        ctxBlock.drawImage(bgImg, 0, 0, props.width, props.height);

        // console.log(this.blockRect.x, this.blockRect.y, this.blockRect.w)
        const _yPos = blockRect.y - 2 * blockRect.r;
        const imageData = ctxBlock.getImageData(blockRect.x, _yPos, blockRect.w, blockRect.w + 1);
        canvas_block.value.width = blockRect.w;
        ctxBlock.putImageData(imageData, 0, _yPos)
      }
      //console.log(bgImg)
    };
	const getBlockPostion= ()=> {
		const xPos = Math.floor(props.width / 2 +  Math.random() * (props.width / 2 - blockRect.w));
		const yPos = Math.floor(30 + Math.random() * (props.height - blockRect.w -30));
		// console.log(xPos, yPos)
		blockRect.x = xPos;
		blockRect.y = yPos;
		draw(ctxImg, 'fill');
		draw(ctxBlock, 'clip')
	}
	const draw =(ctx, operation)=> {
		const { r, x, y } = blockRect;
		const _w =props.blockW;
		ctx.beginPath();
		ctx.moveTo(x, y);
		ctx.arc(x + _w / 2, y - r + 2, r, 0.72 * Math.PI, 2.26 * Math.PI);
		ctx.lineTo(x + _w, y);
		ctx.arc(x + _w + r - 2, y + _w / 2, r, 1.21 * Math.PI, 2.78 * Math.PI);
		ctx.lineTo(x + _w, y + _w);
		ctx.lineTo(x, y + _w);
		ctx.arc(x + r - 2, y + _w / 2, r + 0.4, 2.76 * Math.PI, 1.24 * Math.PI, true);
		ctx.lineTo(x, y);
		ctx.closePath();
		ctx.fillStyle = 'rgba(225, 225, 225, 0.8)';
		ctx.strokeStyle = 'rgba(225, 225, 225, 0.8)';
		ctx.lineWidth = 2;
		ctx.stroke();
		ctx[operation]();
	}
			// pc
	const slidericon=ref(null);
	const mouseEvent= ()=> {// eslint-disable-line no-unused-vars
		nextTick(()=>{
		slidericon.value.addEventListener('mousedown', mouseDown);
		document.addEventListener('mousemove', mouseMove);
		document.addEventListener('mouseup', mouseUp);
		})
	}
	const mouseDown =(e)=> {
		//console.log(e)
		startX = e.pageX;
		showText.value = false;
		isMouseDown = true;
	}
	const mouseMove= (e)=> {
		if (!isMouseDown) {
			return
		}
		// console.log(e)
		endX = e.pageX - startX;
		// 禁止超出边界
		if (endX < 0 || endX > props.width - props.blockW) {
			return
		}
		// 拖动的距离
		sliderLeft.value = endX;
		blockLeft.value = sliderLeft.value / (props.width - props.blockW) * (props.width - blockRect.w);
		slideState.value = 'active';
	}
	const mouseUp= (e) =>{
		if (!isMouseDown || startX === e.clientX) {
			return
		}
		isMouseDown = false;
		const isPass = verify();
		//console.log(isPass)
		if (isPass) {
			slideState.value = 'success';
			console.log('成功');
			//登录
            emit('loginfun')
			console.log('成功11');
		} else {
			slideState.value = 'fail';
			// 如果失败则1000毫秒后重置
			timeIns = setTimeout(() => {
				reset()
			}, 1000)
		}
	}
	
	// 判断精度
	const verify= ()=> {
		//console.log(props.accuracy);
		//console.log(Math.abs(blockLeft.value - blockRect.x))
		return Math.abs(blockLeft.value - blockRect.x) <= props.accuracy
	}
	// 重置
	const reset =()=> {
		showText.value = true;
		slideState.value = '';
		sliderLeft.value = 0;
		blockLeft.value = 0;
		canvas_block.value.width = props.width;
		ctxImg.clearRect(0, 0, props.width, props.height);
		ctxBlock.clearRect(0, 0, props.width, props.height);
		getImg();
	}
	onMounted(()=>{
		init()
		// 如果是pc端则用mouse事件
		mouseEvent()
	})
	onBeforeUnmount(()=>{
		clearTimeout(timeIns)
	})
    return {
      showText,
      slideState,
      blockLeft,
      sliderLeft,
      reset,
      mouseDown,
      mouseMove,
      mouseUp,
      canvasimg,
      canvas_block,
      slidericon
    }
  },
}
</script>
<style lang="scss" scoped>
.verify-container {
			/*width: 350px;*/
			/*border: 1px solid #e4e4e4;*/
			position: relative;
			overflow: hidden;
			user-select:none;
		}
		.pic {
			width: 100%;
			position: relative;
		}
		.canvas_img {
			display: block;
		}
		.canvas_block {
			position: absolute;
			left: 0;
			top: 0;
		}
		.slider {
			/*width: 100%;*/
			height: 40px;
			border: 1px solid #e4e7eb;
			background: #f7f9fa;
			position: relative;
			margin-top: 20px;
		}
		.tip {
			width: 100%;
			height: 100%;
			color: #666;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
		}
		.bar {
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
		.active {
			background: #D1E9FE;
			border: 1px solid #1991FA;
		}
		.success {
			background: #D2F4EF;
			border: 1px solid #52CCBA;
		}
		.fail {
			background: #fce1e1;
			border: 1px solid #f57a7a;
		}
		.slider-icon {
			width: 40px;
			height: 100%;
			/*background: rgba(225, 225, 225, 0.6);
			box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
			border: 1px solid #e3e3e3;*/
			color: #666;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: 0;
			top: 0;
			cursor: pointer;
			background-color: #fff;
			box-shadow: 0 0 3px rgb(0 0 0 / 30%);
			transition: background .2s linear;
		}
		.slider-icon.success {
			background: #52CCBA;
		}
		.slider-icon.active {
			color: #fff;
			background: #1991FA;
			border: 1px solid #1991FA;
		}
		.slider-icon.success {
			color: #fff;
			background: #52CCBA;
			border: 1px solid #52CCBA;
		}
		.slider-icon.fail {
			color: #fff;
			background: #f57a7a;
			border: 1px solid #f57a7a;
		}
		.refresh {
			display: flex;
			position: absolute;
			right: 10px;
			top: 10px;
			z-index: 2;
			cursor: pointer;
		}
</style>