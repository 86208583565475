
import axios from 'axios'
import { ElMessage } from 'element-plus';

//创建axios实例,后续所有通过实例发送的请求，都要受当前配置约束
const $http=axios.create({
    baseURL: 'https://sso.chinaecono.com',//'https://sso.chinaecono.com',//'/api1',//'https://8.131.245.178:36700',
})
//添加请求拦截器
$http.interceptors.request.use(function(config){
    //在发送请求之前做些什么，如token
    return config;
},function(error){
    //对请求错误做些什么
    return Promise.reject(error);
})
//添加相应拦截器
$http.interceptors.response.use(res=>{   
  if(!res)
  {
    ElMessage.success({
      message: '服务器错误！', 
    });   
    return; 
  } 
  else
  {
    return res.data;
  } 
},err=>{  
  ElMessage.success({
    message: '请重新登录'+err, 
  });   
  return ;
}) 

//登录
export const login=(data)=>{
    return $http.post(`/api/Account/Login`,data);
}
//刷新token
export const refreshToken=(data)=>{
    return $http.post('/api/Account/RefreshToken',data);
}
//发送验证码
export const sendVerifyCode=(phone,type)=>{
  return $http.get(`/api/Account/SendVerifyCode?phone=${phone}&type=${type}`);
}
//退出
export const loginOut=(data)=>{
  return $http.post(`/api/Account/LoginOut`,data);
}

