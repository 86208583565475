
//import Home from '@/views/Home.vue'
import Index from '@/views/Index.vue'
import Login from '@/views/login.vue'
/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中，示例看QQ群路由配置
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面在切换标签后不会缓存，如果需要缓存，无需设置这个字段，而且需要设置页面组件name属性和路由配置的name一致
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 * }
 */

export const mainRouter =  [
    //淄博市-调整 2023-04-17
    {
      path: '/',
      name: 'Index',
      component: Index,
      hidden:false,
      meta:{
        isShow:false,
        title:'首页'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      hidden:false,
      meta:{
        isShow:false,
        title:'登录'
      }
    },
    {
      path:'/pdf',
      name:'pdf',
      component:()=>import(/* webpackChunkName: "pdf" */ '../views/pdf.vue'),
      meta:{
        isShow:false,
        title:'pdf浏览'
      },
    },
    {
      path:'/link',
      name:'Link',
      component:()=>import(/* webpackChunkName: "Link" */ '../views/link.vue'),
      meta:{
        isShow:false,
        title:'跳转页'
      },
    },
    {
      path:'/register',
      name:'register',
      component:()=>import(/* webpackChunkName: "register" */ '../views/register.vue'),
      meta:{
        isShow:false,
        title:'注册'
      },
    },
    {
      path:'/forgetpassword',
      name:'forgetPassword',
      component:()=>import(/* webpackChunkName: "forgetPassword" */ '../views/forgetPassword.vue'),
      meta:{
        isShow:false,
        title:'找回密码'
      },
    },
    {
      path:'/help/aboutus',
      name:'aboutus',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/aboutus.vue'),
      meta:{
        isShow:false,
        title:'关于我们'
      },
    },
    {
      path:'/help/contact',
      name:'contact',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/contact.vue'),
      meta:{
        isShow:false,
        title:'联系我们'
      },
    },
    {
      path:'/help/disclaimer',
      name:'disclaimer',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/disclaimer.vue'),
      meta:{
        isShow:false,
        title:'免责声明'
      },
    },
    {
      path:'/help/userHelp',
      name:'userHelp',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/userHelp.vue'),
      meta:{
        isShow:false,
        title:'用户帮助'
      },
    },
    {
      path:'/help/feedback',
      name:'feedback',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/feedback.vue'),
      meta:{
        isShow:false,
        title:'意见反馈'
      },
    },
    {
      path:'/help/privacyPolicy',
      name:'privacyPolicy',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/privacyPolicy.vue'),
      meta:{
        isShow:false,
        title:'隐私权政策'
      },
    },
    {
      path:'/help/userContent',
      name:'userContent',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/userContent.vue'),
      meta:{
        isShow:false,
        title:'用户协议'
      },
    },
    {
      path:'/help/auth',
      name:'auth',
      component:()=>import(/* webpackChunkName: "help" */ '../views/help/auth.vue'),
      meta:{
        isShow:false,
        title:'权限详情'
      },
    },
    {
      path:'/investment/index',
      name:'investment',
      component:()=>import(/* webpackChunkName: "help" */ '../views/investment/index.vue'),
      meta:{
        isShow:false,
        title:'精准招商'
      },
    },
    {
        path: '/404',
        name: 'page404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
      }
  ]

export const appRouter = []

export default [
  ...mainRouter,
  ...appRouter
]